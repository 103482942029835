import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import App from "../App";
import Homepage from "../pages/Homepage";
import FacilityPage from "../pages/FacilityPage";
import VisitorFaciilitySelection from "../pages/VisitorFaciilitySelection";
import { Login } from "../pages/Login";
import { useAuth } from "../modules/auth/core/AuthContext";
import VisitorCitySelection from "../pages/VisitorCitySelection";
import Pricing from "../pages/Pricing";
import Header from "../components/shared/Header";
import Footer from "../components/shared/Footer";
import ForgotPassword from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";
import CustomMap from "../components/Maps/CustomMap";
import Chatbot from "../pages/Chatbot";
import ChatbotSummary from "../pages/ChatbotSummary";
import Signup from "../pages/Signup";
import AuthLayout from "../layouts/AuthLayout";
import UserLayout from "../layouts/UserLayout";
import { VerifyEmail } from "../pages/VerifyEmail";
import ChatForm from "../components/chatbot/ChatForm";
import MyTrip from "../pages/MyTrip";
import FacilityCompare from "../pages/FacilityCompare";
import CityCompare from "../pages/CityCompare";
import CitySelectionList from "../pages/CitySelectionList";
import MyFavorite from "../pages/MyFavorite";
import Checkout from "../pages/Checkout";
import Airlines from "../pages/Airlines";
import Hotels from "../pages/Hotels";
import PassportAndVisa from "../pages/PassportAndVisa";
import BlogsAndArticles from "../pages/BlogsAndArticles";
import Sitemap from "../pages/Sitemap";
import Press from "../pages/Press";
import Contactus from "../pages/Contactus";
import Aboutus from "../pages/Aboutus";
import FeatureList from "../pages/FeatureList";
import Careers from "../pages/Careers";
import HospitalsAndDoctors from "../pages/HospitalsAndDoctors";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermsOfService from "../pages/TermsOfService";
import HowItWorks from "../pages/HowItWorks";
import ProviderHelp from "../pages/ProviderHelp";
import FaciilitySelection from "../pages/FacilitySelection";
import AppointmentSchedule from "../pages/AppointmentSchedule";
import BluditBlogs from "../components/Cards/BluditBlogs";

const { PUBLIC_URL }: any = process.env + "/React";

const AppRoutes = () => {
  const { currentUser } = useAuth();
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<App />}>
          <Route element={<UserLayout />}>
            <Route
              path="/location/:countryName/:cityName/:facilityName"
              element={<FacilityPage />}
            />
            {/* <Route
              path="/schedule-appointment/:facilityId/:treatmentId?"
              element={<AppointmentSchedule />}
            /> */}
            {/* <Route
              path="/city/:countryName/:cityName/:treatmentName"
              element={<VisitorFaciilitySelection />}
            /> */}
            <Route
              path="/treatment/:treatmentName/city-filter/*"
              element={<FaciilitySelection />}
            />
            <Route path="/treatment/:treatmentName/city-filter/:treatmentName-blog" element={<BluditBlogs />}/>
            {currentUser && (
              <Route path="/myfavorites" element={<MyFavorite />} />
            )}
            <Route
              path="/compare/:facilityCompareNames"
              element={<FacilityCompare />}
            />
            <Route
              path="/compare-city/:cityCompareNames"
              element={<CityCompare />}
            />

            <Route path="/cityselection" element={<CitySelectionList />} />
            <Route path="reset-password" element={<ResetPassword />} />
            <Route
              path="hospitals-and-doctors"
              element={<HospitalsAndDoctors />}
            />
            <Route path="airlines" element={<Airlines />} />
            <Route path="hotels" element={<Hotels />} />
            <Route path="passport-and-visas" element={<PassportAndVisa />} />
            {/* <Route path="blogs-and-articles" element={<BlogsAndArticles />} /> */}
            <Route path="sitemap" element={<Sitemap />} />
            <Route path="press" element={<Press />} />
            <Route path="contact-us" element={<Contactus />} />
            <Route path="about-us" element={<Aboutus />} />
            <Route path="feature-list" element={<FeatureList />} />
            <Route path="careers" element={<Careers />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="terms-of-service" element={<TermsOfService />} />
            <Route path="how-it-works" element={<HowItWorks />} />
            <Route path="provider-help" element={<ProviderHelp />} />

            {/* <Route path="/chatbot" element={<Chatbot />} />
                <Route path="/chatsummary" element={<ChatbotSummary />} />
                <Route path="/chatform" element={<ChatForm />} />
                <Route path="/pricing" element={<Pricing />} />
                <Route path="/mytrip" element={<MyTrip />} />
                <Route path="map" element={<CustomMap />} /> */}
            <Route path="/" element={<Homepage />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
          <Route element={<AuthLayout />}>
            {/* <Route path="/auth/signup" element={<Signup />} />
            <Route path="/auth/login" element={<Login />} /> */}
            <Route
              path="/auth/forget-password/:token"
              element={<ResetPassword />}
            />
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
