import React from 'react';

interface PreloadImageProps {
  src: string;
  className?:string;
  alt?:string;
}

const PreloadImage:React.FC<PreloadImageProps> = ({ src,className,alt }) => {
  React.useEffect(() => {
    const link = document.createElement('link');
    link.rel = 'preload';
    link.as = 'image';
    link.href = src;
    document.head.appendChild(link);
    return () => {
      document.head.removeChild(link);
    };
  }, [src]);

   // Render an actual <img> tag so styles and alt text can be applied
  return <img src={src} className={className} alt={alt} />;
};

export default PreloadImage;
