import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CureValueService } from "../../helpers/ServiceWrapper";
import VisitorSelectionCard from "../components/Cards/VisitorSelectionCard";
import CitySelection from "../components/Visitor/CitySelection";
import FaqNew from "../components/shared/FaqNew";
import { getAllCities as getAllCitiesService } from "../modules/city";
import {
  City,
  CountryWiseCity,
  getCityByName,
  RegionWiseCountry,
} from "../modules/city";
import { getCountryByName } from "../modules/country/core/countryService";
import { getAllFacilityByCityName } from "../modules/facility";
import {getFacilitiesByAffordability} from "../modules/facility"
import { Facility } from "../modules/facility/core/_models";
import { TreatmentContextModel, useTreatment } from "../modules/treatment";
import { MdArrowBackIos, MdEmail } from "react-icons/md";
import Loader from "../components/shared/Loader";
import {
  constructURLSegment,
  deConstructURLSegment,
  getURLFromString,
} from "../../helpers/CVHelper";
import CountryAccordian from "../components/shared/CountryAccordian";
import _ from "lodash";
import { IoClose, IoLogoWhatsapp } from "react-icons/io5";
import { FiShare } from "react-icons/fi";
import { FaCopy, FaFacebookSquare } from "react-icons/fa";
import {
  EmailShareButton,
  FacebookShareButton,
  WhatsappShareButton,
} from "react-share";
import { useEventEmmiter } from "../components/shared/eventEmmiter";
import { Helmet } from "react-helmet";
import {Tabs,Tab,Box} from '@mui/material'
import SearchPageMap from '../../components/Maps/SearchPageMap'
import BluditBlogs from "../components/Cards/BluditBlogs";
import { divIcon } from "leaflet";

const FaciilitySelection = () => {
  const [selectedTab,setSelectedTab] = useState(0)
  const treatmentName = useParams().treatmentName as string;
  const { selectedTreatment }: any = useTreatment();
  const [cityName, setCityName] = useState("");
  const [countryName, setcountryName] = useState("");
  const navigate = useNavigate();
  const [allCities, setAllCities] = useState<any>([]);
  const [allFacilities, setAllFacilities] = useState<Facility[]>([]);
  //   const [selectedTreatment, setSelectedTreatment] = useState<any>(null);
  const [filteredAccreditations, setFilteredAccreditations] = useState<any>([]);

  const [selectedLivingCosts, setSelectedLivingCosts] = useState<string[]>(
    () => JSON.parse(localStorage.getItem("selectedLivingCosts") || "[]")
  );
  const [ticksLivingCost,setTicksLivingCost] =  useState<string | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false); // State for the single accordion
  const [regionWiseCountries, setRegionWiseCountries] = useState<
    RegionWiseCountry[]
  >([]);
  const [isTreatmentModelOpen, setIsTreatmentModelOpen] =
    useState<boolean>(false);
  const [filteredLanguages, setFilteredLanguages] = useState<any>([]);
  const [filteredRatings, setFilteredRatings] = useState<any>(null);
  const [selectedCity, setSelectedCity] = useState<any>("");
  const [selectedCountry, setSelectedCountry] = useState<any>("");
  const [facilityOffset, setFacilityOffset] = useState(0);
  const [facilityLimit, setFacilityLimit] = useState(10);
  const [facilityTotalCount, setFacilityTotalCount] = useState<any>(10);
  const [totalFacilitiesCount, setTotalFacilitiesCount] = useState<any>();
  const [isVisaRequired, setIsVisaReuired] = useState(false);
  const [isCitiesLoading, setIsCitiesLoading] = useState(false);
  const [isFacilityLoading, setIsFacilityLoading] = useState(false);
  const [citySelectionPopup, setCitySelectionPopup] = useState(false);
  const [virtualVisits, setVirtualVisits] = useState(false);
  const [allSelectedCities, setAllSelectedCities] = useState<Set<string>>(
    new Set()
  );
  const [filteredCities, setFilteredCities] =
    useState<Set<string>>(allSelectedCities);
  const [isInitialized, setIsInitialized] = useState(false); // Flag for initialization
  // const [selectedCities, setSelectedCities] = useState<any>([]);
  const [countrySelectedCities, setCountrySelectedCities] = useState<
    Map<string, Set<string>>
  >(new Map());
  const [selectedLanguages, setSelectedLanguages] = useState<any>([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [shareUrl, setShareUrl] = useState<any>(null);
  const [selectedVisaRequirement, setSelectedVisaRequirement] = useState<
    string | null
  >(null);
  const [mapAllFacilities,setmapAllFacilities] = useState<any>([])
  const totalCityRecords = useRef(0);
  const eventEmmiter = useEventEmmiter();
  const location = useLocation();
  // console.log(allFacilities)
  // This is jsonLD
  let jsonLD = {
  "@context": "https://schema.org",
  "@type": "MedicalSearchResultsPage",
  "name": "Medical Treatment Search Results",
  "description": "Search results for medical treatments and conditions",
  "keywords": "Medical Tourism, Treatment Search",
  "mainEntity": {
    "@type": "MedicalSearchResults",
    "itemListElement": allFacilities.map(facility => ({
      "@type": "MedicalOrganization",
      "name": facility.facility_name,
      "address": {
        "@type": "PostalAddress",
        "streetAddress": facility.address || "",
        "addressLocality": facility.city_name || "",
        "addressCountry": facility.country_name || ""
      },
      "ratingValue": parseFloat(facility.google_rating) || 0,
      "ratingCount": parseInt(facility.curevalue_rating) || 0,
      "description": facility.facility_description || "",
      "priceSpecification": {
        "@type": "UnitPriceSpecification",
        "priceCurrency": "USD",
        "price": facility.affordability_index || "",
        "costSavings": `${facility.facility_saves_index || 0}%`
      },
      "url": facility.facility_url || ""
    }))
  }
};

// console.log(jsonLD);

  const handleTabChange = (newValue:number) => {
    setSelectedTab(newValue)
  }



  const [selectedSort, setSelectedSort] = useState({
    id: 1,
    sortBy: "Ratings",
  });

  const allLanguages = [
    {
      id: 1,
      language: "English",
    },
    {
      id: 2,
      language: "Spanish",
    },
  ];

  const allVisaRequirements = [
    {
      id: 1,
      requirement: "Required",
    },
    {
      id: 2,
      requirement: "Not Required",
    },
  ];

  const costOfLiving = [
    {
      id: 1,
      livingCost: "Very Affordable",
    },
    {
      id: 2,
      livingCost: "Affordable",
    },
    {
      id: 3,
      livingCost: "Expensive",
    },
  ];

  const accreditations = [
    {
      id: 1,
      accreditation: "JCI",
    },
    {
      id: 2,
      accreditation: "NABH",
    },
    {
      id: 3,
      accreditation: "AACI",
    },
    {
      id: 4,
      accreditation: "ACHC",
    },
  ];

  const googleRatings = [
    {
      id: 1,
      rating: "4.5",
    },
    {
      id: 2,
      rating: "4.0",
    },
    {
      id: 3,
      rating: "3.5",
    },
    {
      id: 4,
      rating: "3.0",
    },
  ];

  const sortingOptions = [
    {
      id: 1,
      sortBy: "Ratings",
    },
    {
      id: 2,
      sortBy: "Distance",
    },
  ];

  useEffect(() => {
    // Retrieve the current treatment from localStorage
    const storedData = JSON.parse(localStorage.getItem("cv-treatment-app") ?? "{}");
    const currentTreatment = storedData.treatment || "";

    // Check if accreditations need resetting
    if (currentTreatment) {
      // Clear selectedAccreditations for any treatment change
      localStorage.setItem("selectedLivingCosts", JSON.stringify([]));
      setSelectedLivingCosts([]);
    }

    // Load existing accreditations into state if any
    const storedAccreditations = JSON.parse(localStorage.getItem("selectedLivingCosts") ?? "[]");
    setSelectedLivingCosts(storedAccreditations);
  }, []);


  const handleLivingCostChange = (value: string) => {
    setSelectedLivingCosts((prev) => {
      const updatedSelection = prev.includes(value)
        ? prev.filter((item) => item !== value) // Remove if already selected
        : [...prev, value]; // Add if not selected  

      // Sync with localStorage
      localStorage.setItem(
        "selectedLivingCosts",
        JSON.stringify(updatedSelection)
      );

      return updatedSelection;
    });
  };

  useEffect(() => {
    // Load initial state from localStorage
    const storedSelection = JSON.parse(
      localStorage.getItem("selectedLivingCosts") || "[]"
    );
    setSelectedLivingCosts(storedSelection);
  }, []);
  

   // Effect to handle treatment changes dynamically
  useEffect(() => {
    // Retrieve the current treatment from localStorage
    const storedData = JSON.parse(localStorage.getItem("cv-treatment-app") ?? "{}");
    const currentTreatment = storedData.treatment || "";

    // Check if accreditations need resetting
    if (currentTreatment) {
      // Clear selectedAccreditations for any treatment change
      localStorage.setItem("selectedAccreditations", JSON.stringify([]));
      setFilteredAccreditations([]);
    }

    // Load existing accreditations into state if any
    const storedAccreditations = JSON.parse(localStorage.getItem("selectedAccreditations") ?? "[]");
    setFilteredAccreditations(storedAccreditations);
  }, []);


  const handleAccreditationChange = (accreditation: string, isChecked: boolean) => {
  // Retrieve the current accreditations from localStorage
  const storedAccreditations = JSON.parse(localStorage.getItem("selectedAccreditations") ?? "[]");

  // Update the list based on whether the checkbox was checked or unchecked
  const updatedAccreditations = isChecked
    ? [...storedAccreditations, accreditation] // Add if checked
    : storedAccreditations.filter((item: string) => item !== accreditation); // Remove if unchecked

  // Store the updated array in localStorage
  localStorage.setItem("selectedAccreditations", JSON.stringify(updatedAccreditations));

  // Update the state with the updated accreditations
  setFilteredAccreditations(updatedAccreditations);
};

  useEffect(() => {
    // Load the initial state from localStorage
    const storedAccreditations = JSON.parse(localStorage.getItem("selectedAccreditations") ?? "[]");
    setFilteredAccreditations(storedAccreditations);
  }, []);

  useEffect(() => {
    if (!treatmentName) return;

    // Convert the Set of cities to an array and join them with slashes
    const citiesArray = Array.from(allSelectedCities).map(constructURLSegment);
    const citiesPath = citiesArray.join("/");

    // Generate the URL with the cities
    const textToCopy =
      process.env.REACT_APP_BASEURL +
      "/treatment/" +
      treatmentName.toLowerCase() +
      "/city/" +
      citiesPath;

    // Update the state with the new URL
    setShareUrl(textToCopy);
  }, [treatmentName, allSelectedCities]);

  useEffect(() => {
    const initializeSelectedCitiesAndLanguages = () => {
      const allCities = new Set<string>();

      const storedLanguages = JSON.parse(
        localStorage.getItem("selectedLanguages") ?? "[]"
      );

      const storedVisit = localStorage.getItem("virtualVisits");

      // Extract cities from the URL
      const segments = location.pathname.split(/[\/,]/); // Split by both '/' and ','
      const citySegments = segments.slice(4); // Get the city segments
      if (citySegments.length > 0) {
        citySegments.forEach((city) =>
          allCities.add(deConstructURLSegment(city))
        );
      } else {
        // If no cities in URL, fetch from local storage
        for (const key in localStorage) {
          if (key.startsWith("selectedCities_")) {
            const storedCities = localStorage.getItem(key);
            if (storedCities) {
              const selectedCitiesArray = JSON.parse(storedCities);
              selectedCitiesArray.forEach((city: string) =>
                allCities.add(city)
              );
            }
          }
        }
      }

      // for (const key in localStorage) {
      //   if (key.startsWith("selectedCities_")) {
      //     const storedCities = localStorage.getItem(key);
      //     if (storedCities) {
      //       const selectedCitiesArray = JSON.parse(storedCities);
      //       selectedCitiesArray.forEach((city: string) => allCities.add(city));
      //     }
      //   }
      // }

      if (storedLanguages) {
        setSelectedLanguages(storedLanguages);
      }

      if (storedVisit == "true") {
        setVirtualVisits(true);
      }

      setAllSelectedCities(allCities);
      setFilteredCities(allCities);

      setIsInitialized(true); // Mark initialization as complete
    };

    initializeSelectedCitiesAndLanguages();
  }, [location.pathname]);

  const storeFilterData = () => {
    localStorage.setItem(
      "selectedLanguages",
      JSON.stringify(selectedLanguages)
    );
    localStorage.setItem("virtualVisits", "" + virtualVisits);
  };

  const removeAllLanguages = () => {
    localStorage.removeItem("selectedLanguages");
    setSelectedLanguages([]);
  };
  // console.log(regionWiseCountries)

  const getAllCities = async (payload: { treatmentName: any }) => {
    const { data, error } = await CureValueService(
      getAllCitiesService,
      payload
      //   { setIsCitiesLoading }
    );
    if (data) {
      // console.log(data)
      const sortedData: any[] = [];
      const regionGroupedData = _.chain(data)
        .filter((city) => (city.facilities_count ?? 0) > 0)
        .groupBy("regionName")
        .map((value, key) => ({ regionName: key, cities: value }))
        .value();

      regionGroupedData.forEach((regionData) => {
        const countryGroupedData = _.chain(regionData.cities)
          .groupBy("country_name")
          .map((cities, countryName) => {
            const totalFacilityCount = cities.reduce(
              (sum, city) => sum + (city.facilities_count || 0),
              0
            );
            // console.log(regionData.cities)
            return {
              country_name: countryName,
              total_facility_count: totalFacilityCount,
              cities: cities,
            };
          })
          .value();

        sortedData.push({
          regionName: regionData.regionName,
          countries: [...countryGroupedData],
        });
      });
      // console.log(sortedData)

      setRegionWiseCountries(sortedData);
    }
  };

  const isValidLatLng = (lat: number | null, lng: number | null): boolean => {
    return lat !== null && lng !== null && !isNaN(lat) && !isNaN(lng);
};

  

  //get all facilities by city and treatment id
  const getAllFacByCityName = async (
    payload: {
      country_name: string;
      treatment_name: string;
      city_name: any;
      accreditations: any[];
      languages: any[];
      visa_required: any;
      ratings: any;
      virtual_visits: any;
      sortBy: any;
      offset: any;
      affordability_filter:string[];
      limit: any;
    },
    isInitialLoad: boolean = true
  ) => {
    setIsFacilityLoading(true);
    // debugger
    const { data, error } = await CureValueService(
      getAllFacilityByCityName,
      payload
    );
    if (data) {
      // console.log(data)
      if (isInitialLoad) {
        setTotalFacilitiesCount(data.totalCount);
        setAllFacilities([...data.items]);
        if (data.totalCount == 0) {
          setFacilityTotalCount("no data");
        } else {
          setFacilityTotalCount(data.totalCount - facilityLimit);
        }

        if (data.items.length > 0) {
          setIsVisaReuired(data.items[0].visa_required);
        }
        setIsFacilityLoading(false);

        // Now, fetch all facilities based on totalCount
        const allFacilitiesPayload = {
        country_name: deConstructURLSegment(countryName),
        treatment_name: deConstructURLSegment(treatmentName),
        city_name: Array.from(allSelectedCities),
        accreditations: filteredAccreditations,
        languages: selectedLanguages,
        visa_required: selectedVisaRequirement,
        ratings: filteredRatings,
        virtual_visits: virtualVisits,
        sortBy: selectedSort ? selectedSort : "",
        offset: 0, // Start from the beginning
        affordability_filter:selectedLivingCosts,
        limit: data.totalCount, // Set limit to totalCount
      };
      // debugger  
      // Fetch all facilities
      const { data: allFacilitiesData } = await CureValueService(
        getAllFacilityByCityName,
        allFacilitiesPayload
      );

      // Update the facilities state with all facilities
      if (allFacilitiesData) {
        setmapAllFacilities(allFacilitiesData.items)
      }
      } else {
        setAllFacilities([...allFacilities, ...data.items]);
        setTotalFacilitiesCount(data.totalCount);
        if (data.totalCount == 0) {
          setFacilityTotalCount("no data");
        } else {
          setFacilityTotalCount(data.totalCount - facilityLimit);
        }

        if (data.items.length > 0) {
          setIsVisaReuired(data.items[0].visa_required);
        }
        setIsFacilityLoading(false);
      }
    }
  };

  // this function is connected with the show more button which on clicking shows 10 fac.more
  const handleShowMoreBtn = () => {
    setFacilityLimit(facilityLimit + 10);
  };

  const getSelectedCity = async (
    payload: {
      cityName: string;
    },
    isInitialLoad: boolean = true
  ) => {
    const { data, error } = await CureValueService(getCityByName, payload);
    if (data) {
      if (isInitialLoad) {
        setSelectedCity(data);
      } else {
        setSelectedCity(data);
      }
      // destinationSliderRef.current.slickPrev();
    }
  };

  const getSelectedCountry = async (
    payload: {
      country_name: string;
    },
    isInitialLoad: boolean = true
  ) => {
    const { data, error } = await CureValueService(getCountryByName, payload);
    if (data) {
      if (isInitialLoad) {
        setSelectedCountry(data);
      } else {
        setSelectedCountry(data);
      }
      // destinationSliderRef.current.slickPrev();
    }
  };
  // this useEffect will call on page reload for first time
  useEffect(() => {
    if (!treatmentName) return;

    getAllCities({
      treatmentName: treatmentName ? deConstructURLSegment(treatmentName) : "",
    });

    getSelectedCity({
      cityName: cityName ? deConstructURLSegment(cityName) : "",
    });
  }, [treatmentName]);

  

  useEffect(() => {
    if (!isInitialized) return;
    if (!treatmentName) return;
    if (citySelectionPopup) return;
    setAllFacilities([]);
    console.log(selectedLivingCosts)
    getAllFacByCityName({
      country_name: deConstructURLSegment(countryName),
      treatment_name: deConstructURLSegment(treatmentName),
      city_name: Array.from(allSelectedCities),
      accreditations: filteredAccreditations,
      languages: selectedLanguages,
      visa_required: selectedVisaRequirement,
      ratings: filteredRatings,
      virtual_visits: virtualVisits,
      sortBy: selectedSort ? selectedSort : "",
      offset: facilityOffset,
      affordability_filter:selectedLivingCosts,
      limit: facilityLimit,
    });

    storeFilterData();
  }, [
    allSelectedCities,
    virtualVisits,
    selectedLanguages,
    selectedVisaRequirement,
    isInitialized,
    filteredAccreditations,
    selectedLivingCosts
  ]);


  // this useEffect will call when user click on show more facility button
  useEffect(() => {
    if (facilityLimit < 11) return;
    if (!treatmentName) return;
    getAllFacByCityName({
      country_name: deConstructURLSegment(countryName),
      treatment_name: deConstructURLSegment(treatmentName),
      city_name: Array.from(allSelectedCities),
      accreditations: filteredAccreditations,
      languages: selectedLanguages,
      visa_required: selectedVisaRequirement,
      ratings: filteredRatings,
      virtual_visits: virtualVisits,
      sortBy: selectedSort ? selectedSort : "",
      offset: facilityOffset,
      affordability_filter:selectedLivingCosts,
      limit: facilityLimit,
    });
    storeFilterData();
  }, [facilityLimit]);

  useEffect(() => {
    if (!countryName) return;
    getSelectedCountry({
      country_name: deConstructURLSegment(countryName),
    });
  }, [countryName]);

  const handleSelectedCity = (event: any) => {
    const cityName = event.target.value;
    const city = allCities.find((city: any) => city.city_name === cityName);
    setSelectedCity(city);
  };

  const handleonClose = (treatment: TreatmentContextModel | null) => {
    if (treatment?.treatment) {
      navigate(
        `/cityselection?service=${getURLFromString(treatment?.treatment)}`
      );
    }
    setIsTreatmentModelOpen(false);
  };

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const handleCityClick = (city: City) => {
    if (selectedTreatment) {
      setCityName(city.city_name);
      setcountryName(city.country_name);
      setIsOpen(!isOpen);
    }
  };

  type CountryCitySelections = Record<string, Set<string>>;
  const [countryCitySelections, setCountryCitySelections] =
    useState<CountryCitySelections>({});

  const removeCityFromAllSelectedCities = (cityName: string) => {
    setAllSelectedCities((prevSelectedCities: any) => {
      if (prevSelectedCities instanceof Set) {
        const newSelectedCities = new Set(prevSelectedCities);
        newSelectedCities.delete(cityName);
        return newSelectedCities;
      } else if (
        typeof prevSelectedCities === "object" &&
        prevSelectedCities !== null
      ) {
        const newCountryCitySelections: CountryCitySelections = {
          ...prevSelectedCities,
        };
        for (const country in newCountryCitySelections) {
          if (newCountryCitySelections[country] instanceof Set) {
            newCountryCitySelections[country].delete(cityName);
            if (newCountryCitySelections[country].size === 0) {
              delete newCountryCitySelections[country];
            }
          }
        }
        return newCountryCitySelections;
      }
      return prevSelectedCities; // Return as is if the type doesn't match expected structures
    });

    // Remove from local storage for all countries
    for (const key in localStorage) {
      if (key.startsWith("selectedCities_")) {
        const storedCities = localStorage.getItem(key);
        if (storedCities) {
          const selectedCities = new Set(JSON.parse(storedCities));
          if (selectedCities.has(cityName.toLowerCase())) {
            selectedCities.delete(cityName.toLowerCase());
            localStorage.setItem(
              key,
              JSON.stringify(Array.from(selectedCities))
            );
          }
        }
      }
    }

    // Generate the updated URL path with cities separated by commas
    const citiesArray =
      allSelectedCities instanceof Set
        ? Array.from(allSelectedCities).map(deConstructURLSegment)
        : Object.values(allSelectedCities)
            .flatMap((citySet) => Array.from(citySet as Set<string>))
            .map(deConstructURLSegment);
    // const citiesPath = citiesArray.join(",");

    // Filter out the city to be removed
    const updatedCitiesArray = citiesArray.filter((city) => city !== cityName);
    const citiesPath = updatedCitiesArray.map(constructURLSegment).join(",");

    const url = `/treatment/${constructURLSegment(
      treatmentName ? treatmentName : ""
    )}/city-filter/${citiesPath}`;

    setAllSelectedCities(new Set([...updatedCitiesArray]));
    handleClosePopup();
    // Navigate to the updated URL
    navigate(url);
  };

  const handleSelectedSort = (event: any) => {
    const sortName = event.target.value;
    const sort: any = sortingOptions.find(
      (sort: any) => sort.sortBy === sortName
    );
    setSelectedSort(sort);
    // openFilter();
  };

  const handleApplyCitySelectionPopup = () => {
    // Create a new Set to collect all city names
    const citiesSet = new Set<string>();

    // Iterate over allSelectedCities to collect city names
    Object.values(allSelectedCities).forEach((citySet) => {
      citySet.forEach((city: any) => citiesSet.add(constructURLSegment(city)));
    });

    // Convert the Set to an array
    const citiesArray = Array.from(citiesSet);

    // Generate the URL path with cities separated by slashes
    const citiesPath = citiesArray.join(",");
    // const treatmentName = selectedTreatment.treatment;
    const url = `/treatment/${constructURLSegment(
      treatmentName ? treatmentName : ""
    )}/city-filter/${citiesPath}`;

    // Update localStorage with the current selection in lowercase
    Object.entries(allSelectedCities).forEach(([country, cities]) => {
      const lowerCaseCities = Array.from(cities).map((city: any) =>
        city.toLowerCase()
      );
      localStorage.setItem(
        `selectedCities_${country}`,
        JSON.stringify(lowerCaseCities)
      );
    });

    // Update the state with the new Set of cities only
    setAllSelectedCities(citiesSet);
    setFilteredCities(citiesSet);

    // Close the popup
    handleClosePopup();

    // Navigate to the new URL
    navigate(url);
  };

  const updateAllSelectedCities = (
    country: string,
    selectedCities: Set<string>
  ) => {
    setAllSelectedCities((prev) => ({
      ...prev,
      [country]: selectedCities,
    }));
  };

  const handleClearSelection = () => {
    // Clear all relevant entries from local storage
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith("selectedCities_")) {
        localStorage.removeItem(key);
      }
    });

    handleClosePopup();
    setFilteredCities(new Set());
    // setAllSelectedCities(new Set());
    // setCitySelectionPopup(false);

    const url = `/treatment/${constructURLSegment(
      treatmentName ? treatmentName : ""
    )}/city-filter`;

    navigate(url);
  };

  const handleClosePopup = () => {
    setCitySelectionPopup(false);
  };

  const toggleSharePopup = (e: any) => {
    e.preventDefault();
    setIsOpenModal(!isOpenModal);
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(shareUrl)
      .then(() => {
        eventEmmiter.cvNotify({
          message: "Link copied to clipboard successfully",
          type: "INFO",
        });
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const capitalizeTreatmentName = (name:string) => {
    return name
      .split('-')
      .map((word:string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };
  const capitalizedTreatmentName = capitalizeTreatmentName(treatmentName || "");
  const dynamicTitle = `${capitalizedTreatmentName} | CureValue`;
  const dynamicDescription = `Explore world-class hospitals and clinics for ${capitalizedTreatmentName} at unbeatable prices with CureValue. Find trusted medical providers offering cutting-edge treatments in ${cityName || 'your desired location'}, and plan your healthcare journey with ease. Enjoy high-quality care and significant savings on your medical needs, all with CureValue - your trusted partner in global healthcare.`;
  const imageUrl = `${window.location.origin}/media/images/CV-Logo.png`;
  // console.log(allCities)
  // console.log(regionWiseCountries)
  //path="/treatment/:treatmentName/city-filter/*"
  // Ensure valid coordinates before passing to the map
  // (valid)
  const validFacilities = mapAllFacilities.filter((facility: Facility) => 
    isValidLatLng(parseFloat(facility.latitude), parseFloat(facility.longitude))
  );
  return (
    <>
      <Helmet>
        <title>{dynamicTitle}</title>
        <meta
        name="description"
        content={dynamicDescription}
      />
        <meta
          property="og:title"
          content={dynamicTitle}
        />
        <meta
          property="og:description"
          content={dynamicDescription}
        />
        <meta
          property="og:image"
          content={imageUrl}
        />
        <meta property="og:url" content={`${window.location.origin}`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content={dynamicTitle}
        />
        <meta
          name="twitter:description"
          content={dynamicDescription}
        />
        <meta
          name="twitter:image"
          content={`${window.location.origin}/media/images/CV-Logo.png`}
        />
        <script type="application/ld+json">{JSON.stringify(jsonLD)}</script>
        <link
          rel="canonical"
          href={`https://curevalue.org/treatment/${treatmentName}/city-filter/${cityName}`}
        />
      </Helmet>
      {/* {console.log(treatmentName)} */}
      <div>
        <div className="bg-[#F5F7FA] pt-[70px] lg:pt-[80px]">
          <div className="container">

            {/* breadcrumb section start */}

            <div className="flex justify-start items-center px-[24px] pt-[30px]  pb-0 lg:pb-[24px] gap-[20px] lg:gap-[30px]">
              <div className="lg:w-[30%] xl:w-[27%]">
                <div className="flex items-center justify-between  w-[100%]">
                  <div className="flex items-center gap-[4px]  cursor-pointer">
                    <MdArrowBackIos className="text-extraclr3 text-[24px] lg:text-iconclr3 lg:text-[20px]" />
                    <button
                      className="text-pfont lg:text-[18px] font-medium text-sc font-sans whitespace-nowrap backbtn"
                      // onClick={(e) => {
                      //   if (treatmentName) {
                      //     navigate(
                      //       `/cityselection?service=${getURLFromString(
                      //         treatmentName
                      //       )}`
                      //     );
                      //   } else {
                      //     navigate(`/cityselection`);
                      //   }
                      // }}
                      onClick={(e) => {
                        navigate("/");
                      }}
                    >
                      Home
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* breadcrumb section end */}
          </div>

          <div className=" container flex flex-col lg:flex-row items-start gap-[20px] lg:gap-[10px]  lg:pb-[50px] ">
            <div className="w-[100%] lg:w-[30%] xl:w-[27%] lg:sticky rounded border-borderclr lg:border-t-0">
              <CitySelection
                selectedTreatment={treatmentName}
                selectedCity={selectedCity}
                selectedCountry={selectedCountry}
                allCities={allCities}
                setSelectedCity={setSelectedCity}
                isVisaRequired={selectedCity.isVisaRequired}
                setCitySelectionPopup={setCitySelectionPopup}
                allSelectedCities={allSelectedCities}
                removeCityFromAllSelectedCities={
                  removeCityFromAllSelectedCities
                }
                setVirtualVisits={setVirtualVisits}
                virtualVisits={virtualVisits}
                allLanguages={allLanguages}
                selectedLanguages={selectedLanguages}
                setSelectedLanguages={setSelectedLanguages}
                removeAllLanguages={removeAllLanguages}
                allVisaRequirements={allVisaRequirements}
                setSelectedVisaRequirement={setSelectedVisaRequirement}
                selectedVisaRequirement={selectedVisaRequirement}
                costOfLiving={costOfLiving}
                accreditations={accreditations}
                filteredAccreditations={filteredAccreditations}
                selectedLivingCosts={selectedLivingCosts}
                handleAccreditationChange={handleAccreditationChange}
                handleLivingCostChange={handleLivingCostChange}
                handleClearSelection={handleClearSelection}
                citySelectionPopup={citySelectionPopup}
                filteredCities={filteredCities}
              />
            </div>
            <div className="block w-[100%] lg:w-[60%] xl:w-[75%] p-3 pt-0 relative">
              {/* <CityLocation selectedCity={selectedCity} /> */}
              {citySelectionPopup ? (
                <>
                  {/* popup for desktop start */}
                  {/* <div className="hidden lg:block z-[200]  top-0 left-0 w-full h-full  justify-start items-start absolute"> */}
                  <div className=" bg-white p-[20px] lg:py-[20px] lg:pl-[50px] w-[95%] lg:w-[100%] hidden lg:block  lg:absolute z-[15] left-[-20px] right-0 top-[100px] border-borderclr shadow-cardshadow rounded-[20px]">
                    <div className="absolute right-0 top-0 lg:right-[5%] lg:top-[5%]">
                      <span
                        className="close cursor-pointer"
                        onClick={() => {
                          setCitySelectionPopup(false);
                        }}
                      >
                        <IoClose size={"40px"} className="text-black" />
                      </span>
                    </div>

                    <div className="max-h-[80vh] lg:max-h-[42vh] overflow-y-auto">
                      {/* popup content start */}

                      <div className="accordion-content p-4 flex gap-x-[30px] gap-y-[50px] justify-center lg:justify-between 2xl:justify-start flex-wrap">
                        {regionWiseCountries.map(
                          (regionWiseCountry: RegionWiseCountry) => (
                            <div
                              className="flex flex-col gap-[10px] items-left w-[200px]"
                              key={regionWiseCountry.regionName}
                            >
                              <div className="text-h4font font-semibold border-b-pc border-b">
                                {regionWiseCountry.regionName !== "Unknown"
                                  ? regionWiseCountry.regionName
                                  : "Other"}
                              </div>
                              {regionWiseCountry.countries.map(
                                (countryWiseCity: CountryWiseCity) => (
                                  <CountryAccordian
                                    {...countryWiseCity}
                                    onCityClick={handleCityClick}
                                    key={countryWiseCity.country_name}
                                    updateAllSelectedCities={
                                      updateAllSelectedCities
                                    }
                                  />
                                )
                              )}
                            </div>
                          )
                        )}
                        {isFacilityLoading && (
                          <div className="w-full">
                            <Loader />
                          </div>
                        )}
                      </div>

                      {/* popup content end */}
                    </div>

                    <div className="d-grid flex justify-end align-center  w-[100%] pe-[30px] gap-[10px]">
                      <div className="">
                        <button
                          type="button"
                          id="kt_sign_in_submit"
                          className="mt-[10px] text-pc w-[100%] text-[18px] font-bold py-[14px] cursor-pointer group hover:bg-white hover:text-pc disabled:opacity-75 disabled:cursor-not-allowed"
                          onClick={() => {
                            handleClearSelection();
                          }}
                        >
                          CLEAR ALL
                        </button>
                      </div>

                      <div className="">
                        <button
                          type="button"
                          id="kt_sign_in_submit"
                          className="bg-pc mt-[10px]  border border-pc text-white w-[100%] text-pfont font-medium p-[14px] cursor-pointer group hover:bg-white hover:text-pc disabled:opacity-75 disabled:cursor-not-allowed"
                          onClick={() => {
                            handleApplyCitySelectionPopup();
                          }}
                        >
                          APPLY FILTERS
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                  {/* popup for desktop end */}

                  {/* =================================================================================================================================== */}

                  {/* popup for mobile start */}

                  <div className="lg:hidden z-[200] fixed top-0 left-0 w-[100vw] h-full flex justify-center items-center bg-black bg-opacity-50 ">
                    <div className=" bg-white rounded-[24px] p-[20px] lg:py-[50px] lg:pl-[50px] w-[95%] lg:w-[80%]  relative">
                      <div className="absolute right-0 top-0 lg:right-[5%] lg:top-[5%]">
                        <span
                          className="close cursor-pointer"
                          onClick={() => {
                            handleApplyCitySelectionPopup();
                          }}
                        >
                          <IoClose size={"40px"} className="text-black" />
                        </span>
                      </div>

                      <div className="max-h-[80vh] lg:max-h-[70vh] overflow-y-auto">
                        {/* popup content start */}

                        <div className="accordion-content p-4 flex gap-[120px] justify-center lg:justify-between 2xl:justify-start flex-wrap">
                          {regionWiseCountries.map(
                            (regionWiseCountry: RegionWiseCountry) => (
                              <div
                                className="flex flex-col gap-[10px] items-left w-[250px]"
                                key={regionWiseCountry.regionName}
                              >
                                <div className="text-h4font font-semibold border-b-pc border-b">
                                  {regionWiseCountry.regionName !== "Unknown"
                                    ? regionWiseCountry.regionName
                                    : "Other"}
                                </div>
                                {regionWiseCountry.countries.map(
                                  (countryWiseCity: CountryWiseCity) => (
                                    <CountryAccordian
                                      {...countryWiseCity}
                                      onCityClick={handleCityClick}
                                      key={countryWiseCity.country_name}
                                      updateAllSelectedCities={
                                        updateAllSelectedCities
                                      }
                                    />
                                  )
                                )}
                              </div>
                            )
                          )}
                          {isFacilityLoading && (
                            <div className="w-full">
                              <Loader />
                            </div>
                          )}
                        </div>

                        {/* popup content end */}
                      </div>

                      <div className="d-grid flex justify-end align-center mb-[25px] w-[100%] pe-[30px] gap-[10px]">
                        <div className="w-[50%]">
                          <button
                            type="button"
                            id="kt_sign_in_submit"
                            className="mt-[10px] text-pc w-[100%] text-[14px] font-bold py-[14px] cursor-pointer group hover:bg-white hover:text-pc disabled:opacity-75 disabled:cursor-not-allowed"
                            onClick={() => {
                              handleClearSelection();
                            }}
                          >
                            CLEAR ALL
                          </button>
                        </div>

                        <div className="w-[50%]">
                          <button
                            type="button"
                            id="kt_sign_in_submit"
                            className="bg-pc mt-[10px] border border-pc text-white w-[100%] text-pfont font-medium py-[14px] cursor-pointer group hover:bg-white hover:text-pc disabled:opacity-75 disabled:cursor-not-allowed"
                            onClick={() => {
                              handleApplyCitySelectionPopup();
                            }}
                          >
                            APPLY FILTERS
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* popup for mobile end */}
                </>
              ) : (
                <></>
              )}

              {/* <div className="mb-[10px] relative">
                  <div className="flex gap-3 pb-[5px] justify-start mt-[14px]">
                    <h1 className="lg:text-h3font text-[18px] lg:text-[24px] font-semibold pb-[10px] lg:pb-[0] w-fit">
                      {totalFacilitiesCount} Facilities offering{" "}
                      <span className={
                          treatmentName === "fertility-treatment"
                            ? "" 
                            : "capitalize"
                        }>
                        {deConstructURLSegment(
                          treatmentName 
                            ? treatmentName === "fertility-treatment" 
                              ? "Fertility and IVF treatments" 
                              : treatmentName
                            : ""
                        )}
                      </span>
                    </h1>
                    <FiShare
                      size={"30px"}
                      className="cursor-pointer"
                      onClick={(e) => {
                        toggleSharePopup(e);
                      }}
                    />
                  </div>
                <SearchPageMap facilities={validFacilities}/>
              </div>

              <VisitorSelectionCard
                allFacilities={allFacilities}
                countryName={countryName}
                cityName={cityName ? cityName : ""}
                isLoading={isFacilityLoading}
                handleShowMoreBtn={handleShowMoreBtn}
                facilityTotalCount={facilityTotalCount}
                allLanguages={allLanguages}
                setFilteredLanguages={setFilteredLanguages}
                selectedLivingCosts={selectedLivingCosts}
                sortingOptions={sortingOptions}
                setSelectedSort={setSelectedSort}
                city={selectedCity}
              /> */}
              {/* New Bludit intregation part */}
                <div className="mb-[10px] relative">
                    <div className="flex gap-3 pb-[5px] justify-start mt-[14px]">
                        <h1 className="lg:text-h3font text-[18px] lg:text-[24px] font-semibold pb-[10px] lg:pb-[0] w-fit cursor-pointer" onClick={() => handleTabChange(0)}>
                          {totalFacilitiesCount} Facilities offering{" "}
                          <span
                            className={treatmentName === "fertility-treatment" ? "" : "capitalize"}
                          >
                            {deConstructURLSegment(
                              treatmentName
                                ? treatmentName === "fertility-treatment"
                                  ? "Fertility and IVF treatments"
                                  : treatmentName
                                : ""
                            )}
                          </span>
                        </h1>
                      <FiShare
                        size={"30px"}
                        className="cursor-pointer"
                        onClick={(e) => {
                          toggleSharePopup(e);
                        }}
                      />
                      {/* <h1
                        className="lg:text-h3font text-[18px] lg:text-[24px] font-semibold pb-[10px] lg:pb-[0] w-fit cursor-pointer lg:ml-[12rem] md:ml-[12rem]"
                        onClick={() => handleTabChange(1)}
                      >
                        Articles
                      </h1> */}
                    </div>
                  <SearchPageMap facilities={validFacilities} />
                </div>

                {selectedTab === 0 && (
                  <VisitorSelectionCard
                    allFacilities={allFacilities}
                    countryName={countryName}
                    cityName={cityName ? cityName : ""}
                    isLoading={isFacilityLoading}
                    handleShowMoreBtn={handleShowMoreBtn}
                    facilityTotalCount={facilityTotalCount}
                    allLanguages={allLanguages}
                    setFilteredLanguages={setFilteredLanguages}
                    selectedLivingCosts={selectedLivingCosts}
                    sortingOptions={sortingOptions}
                    setSelectedSort={setSelectedSort}
                    city={selectedCity}
                  />
                )}
                {selectedTab === 1 && <BluditBlogs />}
            </div>
          </div>
        </div>
        <FaqNew />
      </div>

      {isOpenModal && (
        <div
          className="z-[200] fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50"
          onClick={(e) => {
            toggleSharePopup(e);
          }}
        >
          <div className="bg-white rounded-[24px] p-[50px] lg:py-[50px] lg:pl-[50px] w-[95%] lg:w-[30%] relative">
            <div className="absolute right-0 top-0 lg:right-[5%] lg:top-[5%]">
              <span
                className="close cursor-pointer"
                onClick={(e) => {
                  toggleSharePopup(e);
                }}
              >
                <IoClose size={"30px"} className="text-black" />
              </span>
            </div>

            <div className="max-h-[80vh] lg:max-h-[70vh] overflow-y-auto flex flex-col">
              <div className="lg:max-w-[100%] modal-hospital">
                <h6 className="capitalize text-[20px] font-semibold text-cardpara pt-[5px] pb-[25px]">
                  Share
                </h6>
                <div className="grid lg:grid-cols-2 items-start gap-[20px] lg:gap-[40px]">
                  <div
                    className="flex items-center justify-center gap-[10px] border border-pc rounded-[10px] cursor-pointer"
                    onClick={copyToClipboard}
                  >
                    <FaCopy size={20} className="text-pc" />
                    <h6 className="capitalize text-anchortext font-semibold text-cardpara  text-center pt-[10px] pb-[10px]">
                      Copy Link
                    </h6>
                  </div>
                  <EmailShareButton url={shareUrl} subject={shareUrl}>
                    <div className="flex justify-center border border-gray-400 rounded-[10px] cursor-pointer items-center  gap-[10px]">
                      <MdEmail size={20} className="text-pc" />
                      <h6 className="capitalize text-anchortext font-semibold text-cardpara text-center pt-[10px] pb-[10px]">
                        Email
                      </h6>
                    </div>
                  </EmailShareButton>
                  <FacebookShareButton url={shareUrl}>
                    <div className="flex justify-center border border-gray-400 rounded-[10px] cursor-pointer items-center gap-[10px]">
                      <FaFacebookSquare size={20} className="text-pc" />
                      <h6 className="capitalize text-anchortext font-semibold text-cardpara text-center pt-[10px] pb-[10px]">
                        Facebook
                      </h6>
                    </div>
                  </FacebookShareButton>
                  <WhatsappShareButton url={shareUrl} title={shareUrl}>
                    <div className="flex justify-center border border-gray-400 rounded-[10px] cursor-pointer items-center gap-[10px]">
                      <IoLogoWhatsapp size={20} className="text-pc" />
                      <h6 className="capitalize text-anchortext font-semibold text-cardpara text-center pt-[10px] pb-[10px]">
                        WhatsApp
                      </h6>
                    </div>
                  </WhatsappShareButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FaciilitySelection;
