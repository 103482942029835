import {
  MdAirlineSeatIndividualSuite,
  MdOutlinePets,
  MdOndemandVideo,
  MdCommute,
} from "react-icons/md";
import { useGetCMS } from "../cms/CMSContext";
import contentIdMap from "../../../assets/content/contentful.json";
import { getURLFromAssetsId } from "../cms/CMSHelper";
import TreatmentSearchPopup from "../shared/TreatmentSearchPopup";
import {
  Treatment,
  TreatmentContextModel,
  useTreatment,
} from "../../modules/treatment";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  getTreatmentFromCMSTreatment,
  getURLFromString,
} from "../../../helpers/CVHelper";

const PopularTreatments = () => {
  const contentId = contentIdMap.homePage.popularTreatmentContentId;
  const { treatments, setSelectedTreatment } = useTreatment();
  const content = useGetCMS(contentId);
  const navigate = useNavigate();
  const [isTreatmentModelOpen, setIsTreatmentModelOpen] = useState<any>(false);
  const handleonClose = (treatment: TreatmentContextModel | null) => {
    if (treatment) {
      navigate(
        `/treatment/${getURLFromString(treatment.treatment)}/city-filter`
      );
    }
    setIsTreatmentModelOpen(false);
  };
  const handleTreatmentClick = (treatmentName: string) => {
    var treatment: Treatment[] = getTreatmentFromCMSTreatment(
      treatmentName,
      treatments
    );

    if (treatment?.length == 1) {
      setSelectedTreatment({
        treatment: treatment[0].name,
        treatmentId: treatment[0]._id,
        subTreatment: "",
        subTreatmentId: "",
      });
      navigate(`/treatment/${getURLFromString(treatment[0].name)}/city-filter`);
    }
  };
  type TreatmentEntry = {
  treatmentName: string;
  // other properties, if needed
};

  // Prioritize treatments
  const prioritizedTreatments = ["Dental", "Fertility", "Cosmetic Surgery", "Hair Transplantation"];
  const sortedEntries = content?.entries
    ?.slice() // Create a shallow copy to avoid mutating the original array
    .sort((a:TreatmentEntry, b:TreatmentEntry) => {
      const indexA = prioritizedTreatments.indexOf(a.treatmentName);
      const indexB = prioritizedTreatments.indexOf(b.treatmentName);

      // Place prioritized treatments at the top, keep others in their original order
      if (indexA !== -1 && indexB !== -1) {
        return indexA - indexB;
      } else if (indexA !== -1) {
        return -1;
      } else if (indexB !== -1) {
        return 1;
      } else {
        return 0; // Keep the order for non-prioritized treatments
      }
    });
  return (
    <>
      {isTreatmentModelOpen && (
        <TreatmentSearchPopup onClose={handleonClose}></TreatmentSearchPopup>
      )}
      <div className="lg:container pop-cards">
        <h2 className="text-h2font lg:text-[38px] font-semibold text-center mx-auto lg:max-w-[40%] pb-[57px]">
          {content?.title}
        </h2>
        <div className="hidden lg:flex items-center justify-center">
          <div className="flex lg:flex-wrap items-start justify-center gap-[30px] gap-x-[59px] gap-y-[50px] 2xl:max-w-[80%] relative z-10 lg:px-auto popular-treatment">
            {sortedEntries?.map((entry: any) => {
              console.log(content)
              return (
                <div
                  className="flex flex-col items-center xl:min-w-[125px]"
                  key={Math.random()}
                >
                  <div
                    onClick={() => handleTreatmentClick(entry.treatmentName)}
                    className="border border-borderclr bg-[#f8f8f8] lg:w-[116px] lg:h-[116px] rounded-[20px] flex items-center justify-center group pop-card cursor-pointer"
                  >
                    {/* <MdAirlineSeatIndividualSuite className="text-[80px] text-iconclr2 group-hover:text-white" /> */}
                    <img
                      src={getURLFromAssetsId(
                        entry?.treatmentIcon?.sys.id,
                        entry
                      )}
                      alt=""
                      className="group-hover:hidden"
                    />
                    <img
                      src={getURLFromAssetsId(
                        entry?.treatmentIconHover?.sys.id,
                        entry
                      )}
                      alt=""
                      className="group-hover:block hidden"
                    />
                  </div>
                  <p className="text-anchortext text-black pt-[5px] max-w-[85%] text-center">
                    {entry.treatmentName === "Fertility" ? "Fertility and IVF" : entry.treatmentName}
                  </p>
                </div>
              );
            })}
          </div>
        </div>

        <div className="lg:hidden grid grid-cols-2 items-start justify-center gap-[42px] popular-treatment px-[50px]">
          {/* <div className="flex flex-col items-center ">
          <div className="border border-borderclr bg-[#f8f8f8] w-[120px] h-[120px] rounded-[20px] flex items-center justify-center group hover:bg-pc">
            <MdAirlineSeatIndividualSuite className="text-[70px] text-iconclr2 group-hover:text-white" />
          </div>
          <p className="text-anchortext text-black pt-[5px] max-w-[85%] text-center">
            Private Rooms
          </p>
        </div> */}
          {sortedEntries?.slice(0, 4).map((entry: any) => {
            return (
              <div
                className="flex flex-col items-center xl:min-w-[125px]"
                key={Math.random()}
              >
                <div
                  onClick={() => handleTreatmentClick(entry.treatmentName)}
                  className="border border-borderclr bg-[#f8f8f8] w-[116px] h-[116px] rounded-[20px] flex items-center justify-center group pop-card"
                >
                  {/* <MdAirlineSeatIndividualSuite className="text-[80px] text-iconclr2 group-hover:text-white" /> */}
                  <img
                    src={getURLFromAssetsId(
                      entry?.treatmentIcon?.sys.id,
                      entry
                    )}
                    alt=""
                    className="group-hover:hidden"
                  />
                  <img
                    src={getURLFromAssetsId(
                      entry?.treatmentIconHover?.sys.id,
                      entry
                    )}
                    alt=""
                    className="group-hover:block hidden"
                  />
                </div>
                <p className="text-anchortext text-black pt-[5px] max-w-[85%] text-center">
                  {entry.treatmentName === "Fertility" ? "Fertility and IVF" : entry.treatmentName}
                </p>
              </div>
            );
          })}
        </div>

        <div className="flex items-start text-center justify-center lg:max-w-[10%] mx-auto pt-[50px] px-[40px]">
          <button
            className="lg:text-h3font/[28px] text-pfont font-medium text-blueclr2 cursor-pointer capitalize hover:text-hoverclr"
            onClick={() => {
              setIsTreatmentModelOpen(true);
            }}
          >
            See more treatments
          </button>
        </div>
      </div>
    </>
  );
};

export default PopularTreatments;
