import { MdQuestionAnswer } from "react-icons/md";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { IoIosArrowDown } from "react-icons/io";
import img1 from "../../../assets/images/acc-img.png";
import React, { FC, useEffect, useRef, useState } from "react";
import img2 from "../../../assets/images/citypopup.png";
import { FaLocationDot } from "react-icons/fa6";
import { LiaHospitalSolid } from "react-icons/lia";
import { CureValueService } from "../../../helpers/ServiceWrapper";
import {
  Facility,
  getRelatedFacilities as getRelatedFacilitiesService,
  getTopRatesFacilities,
} from "../../modules/facility";
import {
  constructURLSegment,
  deConstructURLSegment,
} from "../../../helpers/CVHelper";
import Slider from "react-slick";
import {
  FaRegArrowAltCircleLeft,
  FaRegArrowAltCircleRight,
} from "react-icons/fa";
import FacilityCard, { FacilityCardActionType } from "../facility/FacilityCard";
import Loader from "./Loader";
import { useNavigate } from "react-router-dom";

export interface RelatedFacilitiesProps {
  cityName: string;
  treatmentName: string;
  currentFacilityId: string; //Passing the current facility's ID
}
const RelatedFacilities: FC<RelatedFacilitiesProps> = ({
  cityName,
  treatmentName,
  currentFacilityId //Accepting the current facility's ID
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [facilities, setFacilities] = useState<Facility[]>([]);
  const sliderRef = useRef<any>(null);
  const navigate = useNavigate();
  useEffect(() => {
    getRelatedFacilities({
      city_name: deConstructURLSegment(cityName),
      treatment_name: deConstructURLSegment(treatmentName),
    });
  }, []);
  const getRelatedFacilities = async (payload: {
    city_name: string;
    treatment_name: string;
  }) => {
    const { data, error } = await CureValueService(
      getRelatedFacilitiesService,
      payload,
      { setIsLoading }
    );

    if (data) {
      // Filtering out the current facility
      const filteredFacilities = data.items.filter(
        (facility: Facility) => facility._id !== currentFacilityId
      );
      setFacilities([...filteredFacilities]);
    }
  };

  const handlePrevClick = () => {
    sliderRef.current.slickPrev();
  };

  const handleNextClick = () => {
    sliderRef.current.slickNext();
  };
  const handleCardClick = (
    action: FacilityCardActionType,
    facility: Facility
  ) => {
    navigate(
      `/location/${constructURLSegment(
        facility?.country_name ? facility.country_name : ""
      )}/${constructURLSegment(
        facility?.city_name ? facility.city_name : ""
      )}/${constructURLSegment(
        facility?.facility_name ? facility.facility_name : ""
      )}?service=${treatmentName}`
    );
    // if (selectedTreatment) {
    //   navigate(
    //     `/location/${constructURLSegment(
    //       facility?.country_name ? facility.country_name : ""
    //     )}/${constructURLSegment(
    //       facility?.city_name ? facility.city_name : ""
    //     )}/${constructURLSegment(
    //       facility?.facility_name ? facility.facility_name : ""
    //     )}`
    //   );
    // } else {
    //   setIsTreatmentModelOpen(true);
    // }
  };
  const settings = {
    arrows: true,
    dots: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: false,
    initialSlide: 0,
    infinite: true,
    prevArrow: <FaRegArrowAltCircleLeft />,
    nextArrow: <FaRegArrowAltCircleRight />,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  return (
    <>
      {facilities.length > 0 && (
        <div className="container lg:pt-[50px] pt-[50px]">
          <div className="border-t border-borderclr pt-[20px]">
            <div className="flex items-center gap-[5px] pb-[16px] ">
              <LiaHospitalSolid className="text-iconclr text-h3font lg:text-[30px]" />
              <h2 className="text-sc text-h3font font-semibold lg:text-[20px] lg:font-bold">
                Similar facilities for {deConstructURLSegment(treatmentName)}
              </h2>
            </div>
            <div className="w-[100%] overflow-hidden">
              <div className="destination-slider lg:w-full">
                <Slider
                  {...settings}
                  ref={sliderRef}
                  infinite={facilities.length == 1 ? false : true}
                >
                  {facilities.map((facility: Facility) => {
                    return (
                      <div key={facility._id}>
                        <FacilityCard
                          facility={facility}
                          onClick={(action) => {
                            handleCardClick(action, facility);
                          }}
                          handleShowSignUpPopup={() => {}}
                          showTreatment={false}
                        />
                      </div>
                    );
                  })}
                  {isLoading && <Loader />}
                </Slider>
              </div>
              {facilities.length != 0 && (
                <div
                  className={`slider-arrows flex items-center gap-[25px] 2xl:gap-[45px] pl-[20px] justify-start pt-[20px] 2xl:pt-[30px] ${
                    isLoading ? "pointer-events-none" : "pointer-events-auto"
                  }`}
                >
                  <div className="prev-arrow" onClick={handlePrevClick}>
                    <FaRegArrowAltCircleLeft
                      className={`${
                        isLoading ? "text-gray-400" : "text-cardpara"
                      } text-[38px] 2xl:text-[50px] cursor-pointer hover:text-hoverclr`}
                    />
                  </div>
                  <div className="next-arrow" onClick={handleNextClick}>
                    <FaRegArrowAltCircleRight
                      className={`${
                        isLoading ? "text-gray-400" : "text-cardpara"
                      } text-[38px] 2xl:text-[50px] cursor-pointer hover:text-hoverclr`}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RelatedFacilities;
