import axios from "axios";
import {
  API_FACILITY_URL,
  API_TREATMENT_COST_URL,
} from "../../../components/shared/urlConstants";
import { BaseResponse, ListResponse } from "../../common";
import { Facility } from "./_models";

export function getTopRatesFacilities(payload: {
  offset: number;
  limit: number;
}) {
  return axios
    .post<any, BaseResponse<ListResponse<Facility>>>(
      `${API_FACILITY_URL}getTopRated`,
      payload
    )
    .then((response) => {
      return response.data;
    });
}

//get all facility by city and treatmentid
export function getAllFacilityByCityId(payload: {
  cityId: string;
  treatment_id: string;
}) {
  return axios
    .post<any, BaseResponse<ListResponse<Facility>>>(
      `${API_FACILITY_URL}getAllByCityId`,
      payload
    )
    .then((response) => {
      return response.data;
    });
}

//get all facility by city, country and treatment name
export function getAllFacilityByCityName(payload: {
  country_name: string;
  treatment_name: string;
  city_name: any;
  accreditations: any[];
  languages: any[];
  ratings: any;
  sortBy: any;
  offset: any;
  affordability_filter:string[];
  limit: any;
}) {
  return axios
    .post<any, BaseResponse<ListResponse<Facility>>>(
      `${API_FACILITY_URL}getAllByCityName`,
      payload
    )
    .then((response) => {
      return response.data;
    });
}

export function getFacilitiesByAffordability(payload:{
  affordability:string;
}) {
  return axios
    .post<any, BaseResponse<ListResponse<Facility>>>(
      `${API_FACILITY_URL}getByAffordability`,
      payload
    )
    .then((response) => {
      return response.data;
    });
}

//get facility by facility Id
export function getById(payload: { facilityId: string }) {
  return axios
    .get<Facility>(
      `${API_FACILITY_URL}getById?facilityId=${payload.facilityId}`
    )
    .then((response) => {
      return response.data;
    });
}

//get facility by name
export function getByName(payload: {
  facilityName: string;
  countryName: string;
  cityName: string;
}) {
  const encodedFacility = encodeURIComponent(payload.facilityName);
  return axios
    .get<Facility>(
      `${API_FACILITY_URL}getByName?facilityName=${encodedFacility}&cityName=${payload.cityName}&countryName=${payload.countryName}`
    )
    .then((response) => {
      return response.data;
    });
}

//get all facility by city and treatmentid
export function getTreatmentCost(payload: {
  city_name: string;
  treatment_name: string;
}) {
  return axios
    .post<any>(`${API_TREATMENT_COST_URL}getCost`, payload)
    .then((response) => {
      return response.data;
    });
}

export function getRelatedFacilities(payload: {
  city_name: string;
  treatment_name: string;
}) {
  return axios
    .post<any, BaseResponse<ListResponse<Facility>>>(
      `${API_FACILITY_URL}getRelatedFacilities`,
      payload
    )
    .then((response) => {
      return response.data;
    });
}
