import React, { useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Stories from "../Cards/Stories";
import BluditBlogsStories from "./BluditBlogsStories";
import {
  FaRegArrowAltCircleLeft,
  FaRegArrowAltCircleRight,
} from "react-icons/fa";

const BluditBlogs = () => {
  const [blogs, setBlogs] = useState<any[]>([]);
  const sliderRef = useRef<any>(null);

  // Fetch blogs from Bludit API
  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await fetch(
          "https://curevalue.org/content/api/pages?token=f8874ccbcd1f53839b2fe5069d026937"
        );
        const data = await response.json();
        console.log(data)
        setBlogs(data.data || []); // Assuming 'pages' contains the list of blogs
      } catch (error) {
        console.error("Failed to fetch blogs:", error);
      }
    };
    fetchBlogs();
  }, []);

  // Slider settings
  const settings = {
    arrows: true,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    centerMode: true,
    prevArrow: <FaRegArrowAltCircleLeft />,
    nextArrow: <FaRegArrowAltCircleRight />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const handlePrevClick = () => {
    sliderRef.current.slickPrev();
  };

  const handleNextClick = () => {
    sliderRef.current.slickNext();
  };

  return (
    <div className="overflow-hidden bg-[#F5F7FA] py-[30px]">
      <div className="text-center pb-[30px]">
        <p className="text-lg text-gray-600">
          Explore the latest blogs and articles.
        </p>
      </div>

      {blogs.length > 0 ? (
        <div className="stories-slider">
          <Slider {...settings} ref={sliderRef}>
            {blogs.map((blog) => (
              <div key={blog.slug}>
                {/* Map the Bludit blog data to Stories props */}
                <BluditBlogsStories
                  title={blog.title}
                  subText={blog.tags}
                  // subDescription={blog.content}
                  ctaText="Read More"
                  ctaLink={blog.permalink} // Assuming 'url' contains the link to the blog
                  entries={[
                  {
                    treatmentIconHover: { sys: { id: blog.coverImage } }, // Corrected to coverImage
                    treatmentName: blog.title,
                  },
                ]}
                />
              </div>
            ))}
          </Slider>
          <div className="slider-arrows flex items-center gap-[25px] justify-center pt-[20px]">
            <div className="prev-arrow" onClick={handlePrevClick}>
              <FaRegArrowAltCircleLeft className="text-cardpara text-[38px] cursor-pointer hover:text-hoverclr" />
            </div>
            <div className="next-arrow" onClick={handleNextClick}>
              <FaRegArrowAltCircleRight className="text-cardpara text-[38px] cursor-pointer hover:text-hoverclr" />
            </div>
          </div>
        </div>
      ) : (
        <p className="text-center text-gray-500">No Articles found.</p>
      )}
    </div>
  );
};

export default BluditBlogs;
