import { TokenResponse, useGoogleLogin } from "@react-oauth/google";
import { FcGoogle } from "react-icons/fc";
import { FaFacebookF } from 'react-icons/fa'
import { CallbackFunction } from "../modules/common";
import { CureValueService } from "../../helpers/ServiceWrapper";
import { getUserInfoFromGoogle } from "../modules/auth";

// Facebook login react-social-login
import { LoginSocialFacebook } from "reactjs-social-login";

interface LoginButtonProps {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  onSuccess: CallbackFunction<any>;
  onError: CallbackFunction<any>;
  isDisabled: boolean;
}
const LoginButton = ({
  onError,
  onSuccess,
  setIsLoading,
  isDisabled,
}: LoginButtonProps) => {
  // Google login logic
  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => handleSuccess(tokenResponse),
    onError: (data) => onError(data),
    onNonOAuthError: (error) => {
      onError(error);
    },
    //flow: "auth-code",
  });
  const handleSuccess = async (tokenResponse: TokenResponse) => {
    const { data, error } = await CureValueService(
      getUserInfoFromGoogle,
      tokenResponse
    );
    if (data) {
      onSuccess(data);
    } else {
      onError(error);
    }
  };
  return (
    <div>
      <button
      disabled={isDisabled}
      onClick={() => {
        setIsLoading(true);
        login();
      }}
      className="text-lightblack rounded-full w-[100%] py-[8px] text-center flex items-center justify-center text-pfont gap-[8px] border border-gray-500"
    >
      <FcGoogle className="text-lightblack text-[18px] lg:text-[24px]" />
      Continue with Google
    </button>
    
    {/* Facebook button UI */}
    {/* <LoginSocialFacebook appId="414794497910369" onResolve={(resolve) => {
      console.log(resolve)
    }}
    onReject={(error) => {
      console.log(error)
    }}>
      <button
  disabled={isDisabled}
  onClick={() => {
    setIsLoading(true);
    // loginWithFacebook();
  }}
  className={`
    text-lightblack rounded-full w-[100%] py-[8px] text-center flex items-center justify-center text-pfont gap-[8px] border border-gray-500 mt-[6px]
  `}
>
  <FaFacebookF className="text-[#0866ff] text-[18px] lg:text-[24px] ml-[16px]" />
  Continue with Facebook
      </button>
    </LoginSocialFacebook> */}
  

    </div>
  );
};
export default LoginButton;
