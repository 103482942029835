import arrowimg from "../../assets/images/arrowimage.png";
import locimg from "../../assets/images/loc-img.png";
import { useGetCMS } from "./cms/CMSContext";
import contentIdMap from "../../assets/content/contentful.json";
import { getURLFromAssetsId } from "./cms/CMSHelper";
import { useNavigate } from "react-router-dom";
import { entries } from "lodash";
import SignUpPopup from "./auth/SignUpPopup";
import { useState } from "react";
import { useAuth } from "../modules/auth";
import TreatmentSearchPopup from "./shared/TreatmentSearchPopup";
import { getURLFromString } from "../../helpers/CVHelper";
import { TreatmentContextModel } from "../modules/treatment";

const BuildingCommunity = () => {
  const contentId = contentIdMap.homePage.contentBandWithBox;
  const content = useGetCMS(contentId);
  const navigate = useNavigate();
  const [showSignUpPopup, setShowSignUpPopup] = useState(false);
  const [isTreatmentModelOpen, setIsTreatmentModelOpen] = useState<any>(false);
  const { currentUser, logout } = useAuth();
  const handleShowSignUpPopup = () => {
    setShowSignUpPopup(true);
  };

  const handleShowLoginPopup = () => {
    setShowSignUpPopup(false);
  };

  const handleClosePopup = () => {
    setShowSignUpPopup(false);
  };
  const handleonClose = (treatment: TreatmentContextModel | null) => {
    if (treatment) {
      navigate(
        `/treatment/${getURLFromString(treatment.treatment)}/city-filter`
      );
    }
    setIsTreatmentModelOpen(false);
  };

  return (
    <div className="relative pt:[96px] pb-[135px] lg:py-[230px] business-community">
      {showSignUpPopup && (
        <SignUpPopup
          handleShowSignUpPopup={handleShowSignUpPopup}
          showSignUpPopup={showSignUpPopup}
          handleShowLoginPopup={handleShowLoginPopup}
          handleClosePopup={handleClosePopup}
        />
      )}
      {isTreatmentModelOpen && (
        <TreatmentSearchPopup onClose={handleonClose}></TreatmentSearchPopup>
      )}
      <div className="lg:grid lg:grid-cols-2 items-center container">
        <div>
          <h2 className="text-h2font lg:text-[38px] text-pcolor font-semibold lg:max-w-[90%] 2xl:max-w-[70%] mb-[11px]">
            {content?.title}
          </h2>
          <p className="text-anchortext lg:text-[18px] font-medium mb-[18px]">
            {content?.subTitle}
          </p>

          <button
            className="btn border border-pc bg-pc text-white rounded-full text-[17px] font-medium py-[10px]  px-[27px] hover:bg-white hover:text-pc"
            onClick={() => {
              //navigate(content?.ctaLink);
              if (currentUser) {
                setIsTreatmentModelOpen(true);
              } else {
                handleShowSignUpPopup();
              }
            }}
          >
            {!currentUser ? content?.ctaText : "Select treatment"}
          </button>
        </div>
        <div className="mt-[40px] lg:mt-[0]">
          <img
            src={getURLFromAssetsId(content?.bgImage?.sys.id, content)}
            className="arrowimage absolute right-0 top-[27px] lg:top-0 bottom-0 my-auto z-0 2xl:w-[1000px] px-[24px] lg:px-[0]"
            alt="bgImage"
          />
          <div className="relative z-10">
            <div className="min-w-[200px] max-w-[200px] lg:min-w-[265px] lg:max-w-[265px] border border-hoverclr rounded-[14px] flex items-start px-[17px] py-[10px] lg:py-[24px] lg:px-[24px] gap-[10px] w-fit bg-white lg:absolute bottom:[66px] lg:bottom-unset lg:left-0 lg:right-0 lg:mx-auto right-[-30%] 2xl:right-[-30%] healthy-one md:ml-auto">
              <div className="min-w-[27px] lg:min-w-[39px] pt-[10px]">
                <img
                  src={getURLFromAssetsId(
                    content?.entries[0].assets[0]?.sys.id,
                    content?.entries[0]
                  )}
                  className="w-[27px] lg:w-[39px]"
                  alt="content"
                />
              </div>
              <div>
                <h2 className="text-pcolor font-semibold text-[33px]/[40px] lg:text-[46px]/[55px]">
                  {content?.entries[0].title}
                </h2>
                <p className="text-[10px] lg:text-smalltext text-[#656565] font-semibold">
                  {content?.entries[0].description}
                </p>
              </div>
            </div>
            <div className="min-w-[200px] max-w-[200px] lg:min-w-[265px] lg:max-w-[265px]  border border-hoverclr rounded-[14px] flex items-start px-[17px] py-[10px] lg:py-[24px] lg:px-[24px] gap-[10px] w-fit bg-white lg:absolute md:relative lg:right-0 lg:bottom-[88px] 2xl:bottom-[100px] healthy-two">
              <div className="min-w-[27px] lg:min-w-[39px] pt-[10px]">
                <img
                  src={getURLFromAssetsId(
                    content?.entries[1].assets[0]?.sys.id,
                    content?.entries[1]
                  )}
                  className="w-[27px] lg:w-[39px]"
                  alt="content entries"
                />
              </div>
              <div>
                <h2 className="text-pcolor font-semibold text-[33px]/[40px] lg:text-[46px]/[55px]">
                  {content?.entries[1].title}
                </h2>
                <p className="text-[10px] lg:text-smalltext text-[#656565] font-semibold">
                  {content?.entries[1].description}
                </p>
              </div>
            </div>
            <div className="min-w-[200px] max-w-[200px] lg:min-w-[265px] lg:max-w-[265px]  border border-hoverclr rounded-[14px] flex items-start px-[17px] py-[10px] lg:py-[24px] lg:px-[24px] gap-[10px] w-fit bg-white lg:absolute lg:bottom-[45px] 2xl:left-[12%] healthy-three md:m-auto">
              <div className="min-w-[27px] lg:min-w-[39px] pt-[10px]">
                <img
                  src={getURLFromAssetsId(
                    content?.entries[2].assets[0]?.sys.id,
                    content?.entries[2]
                  )}
                  className="w-[27px] lg:w-[39px]"
                  alt="content entries"
                />
              </div>
              <div>
                <h2 className="text-pcolor font-semibold text-[33px]/[40px] lg:text-[46px]/[55px]">
                  {content?.entries[2].title}
                </h2>
                <p className="text-[10px] lg:text-smalltext text-[#656565] font-semibold">
                  {content?.entries[2].description}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuildingCommunity;
