import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { FaLocationDot } from "react-icons/fa6";

// Define the custom icon
const customIcon = new L.Icon({
  iconUrl:
    "data:image/svg+xml;charset=UTF-8," +
    encodeURIComponent(`
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="50" viewBox="0 0 35 50">
      <path fill="#0f4d92" stroke="#003366" stroke-width="1" d="M17.5 0C7.8 0 0 7.8 0 17.5c0 11.4 17.5 35 17.5 35s17.5-23.6 17.5-35C35 7.8 27.2 0 17.5 0z"/>
      <circle cx="17.5" cy="15" r="6.5" fill="#e5803c" stroke="#ffffff" stroke-width="2"/>
    </svg>
  `),
  iconSize: [22, 37],
  iconAnchor: [11, 37],
  popupAnchor: [0, -37],
});

// Fix Leaflet default icon
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const FacilityLocation = ({ facility }) => {
  console.log("Facility Data:", facility);

  // Validate facility and coordinates
  if (!facility || !facility.latitude || !facility.longitude) {
    return (
      <div className="container">
        <div className="flex items-center gap-[5px] pb-[30px] ">
          <FaLocationDot className="text-iconclr text-[24px] lg:text-[30px]" />
          <h2 className="text-sc text-h3font font-semibold lg:text-[20px] lg:font-bold">
            Location
          </h2>
        </div>
      </div>
    );
  }

  const position = [
    parseFloat(facility.latitude),
    parseFloat(facility.longitude),
  ];

  return (
    <div className="container">
      <div className="flex items-center gap-[5px] pb-[30px]">
        <FaLocationDot className="text-iconclr text-[24px] lg:text-[30px]" />
        <h2 className="text-sc text-h3font font-semibold lg:text-[20px] lg:font-bold">
          Location
        </h2>
      </div>
      <MapContainer
        center={position}
        zoom={10} // Adjusted zoom level for a more zoomed-out view
        className="w-[100%] rounded-[30px] h-[215px] lg:h-[450px] z-0"
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <Marker position={position} icon={customIcon}></Marker>
      </MapContainer>
    </div>
  );
};

export default FacilityLocation;

// Google map implementation(typescript)
// import { useEffect, useState } from "react";
// import { FaLocationDot } from "react-icons/fa6";

// const FacilityLocation = ({ cityName,facility }: any) => {
//   console.log(facility)
//   const [mapSrc, setMapSrc] = useState("https://www.google.com/maps/embed");

//   useEffect(() => {
//     if (!cityName) return;
//     const newSrc = `https://www.google.com/maps?q=${encodeURIComponent(
//       cityName.toLowerCase() + " city"
//     )}&output=embed`;
//     setMapSrc(newSrc);
//   }, [cityName]);
//   return (
//     <div className="container">
//       <div className="flex items-center gap-[5px] pb-[30px] ">
//         <FaLocationDot className="text-iconclr text-h3font lg:text-[30px]" />
//         <h2 className="text-sc text-h3font font-semibold lg:text-[20px] lg:font-bold">
//           Location
//         </h2>
//       </div>
//       <div>
//         <iframe
//           className="w-[100%] rounded-[30px] h-[215px] lg:h-[450px]"
//           src={mapSrc}
//           key="map"
//           width="100%"
//           style={{ border: 0 }}
//           loading="lazy"
//           referrerPolicy="no-referrer-when-downgrade"
//         ></iframe>
//       </div>
//     </div>
//   );
// };

// export default FacilityLocation;
