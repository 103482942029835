import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { useEventEmmiter } from "../components/shared/eventEmmiter";
import { CureValueService } from "../../helpers/ServiceWrapper";
import { requestCallback, useAuth, verifyUserEmail } from "../modules/auth";
import * as Yup from "yup";
import clsx from "clsx";
import { divide, values } from "lodash";
import { json } from "stream/consumers";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Facility } from "../modules/facility";
import axios from "axios";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const loginSchema = Yup.object().shape({
  patientfName: Yup.string().required("First name is required"),
  patientlName: Yup.string(),
  patientDOB: Yup.number()
    .max(100, "Age should be less than 100.")
    .min(1, "Age should be greater than 1"),
  patientGender: Yup.string().oneOf(
    ["Male", "Female", "Other"],
    "Inavlid selection"
  ),
  patientPhno: Yup.string()
    .required("Phone is required")
    .matches(phoneRegExp, "Phone is invalid"),
  patientEmail: Yup.string().required("Email is required"),
  treatmentName: Yup.string(),
  subTreatmentName: Yup.string(),
  shouldWeSchedule: Yup.string().required("This field is required"),
  whenToTravel: Yup.string(),
  bestWayToReach: Yup.string().oneOf(
    ["Phone", "Email", "No Preference"],
    "Inavlid selection"
  ),
  address: Yup.string(),
  apartment: Yup.string(),
  city: Yup.string(),
  state: Yup.string(),
  postalCode: Yup.string(),
});
interface requestAppointment {
  patientfName: string;
  patientlName: string;
  patientDOB: number;
  patientGender: "Male" | "Female" | "Other";
  patientPhno: string;
  patientEmail: string;
  treatmentName: string;
  subTreatmentName: string;
  shouldWeSchedule: string;
  whenToTravel: string;
  bestWayToReach: "Phone" | "Email" | "No Preference";
  address: string;
  apartment: string;
  city: string;
  state: string;
  postalCode: string;
}
const initialValues: requestAppointment = {
  patientfName: "",
  patientlName: "",
  patientDOB: 1,
  patientGender: "Other",
  patientPhno: "",
  patientEmail: "",
  treatmentName: "",
  subTreatmentName: "",
  shouldWeSchedule: "",
  whenToTravel: "",
  bestWayToReach: "No Preference",
  address: "",
  apartment: "",
  city: "",
  state: "",
  postalCode: "",
};

const AppointmentSchedule: FC<{
  treatmentId: string;
  facility: Facility;
  onClose?: () => void;
}> = ({ treatmentId, facility, onClose }) => {
  //const { facilityId, treatmentId } = useParams();
  const currentUser = useAuth();

  const [loading, setLoading] = useState(false);
  const [ip, setIp] = useState("");

  const navigate = useNavigate();
  const [isRequestMade, setIsRequestMade] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      if (formik.isValid) {
        formik.setSubmitting(true);
        const payload = {
          facilityId: facility._id,
          mainTreatmentName: formik.values.treatmentName,
          subTreatmentName: formik.values.subTreatmentName,
          travelDate: formik.values.whenToTravel,
          scheduleFlightAccommodation:
            formik.values.shouldWeSchedule == "No" ? false : true,
          patientFirstName: formik.values.patientfName,
          patientLastName: formik.values.patientlName,
          patientDOB: formik.values.patientDOB.toString(),
          patientGender: formik.values.patientGender,
          phoneNumber: formik.values.patientPhno,
          email: formik.values.patientEmail,
          address: {
            address: formik.values.address,
            apartment: formik.values.apartment,
            city: formik.values.city,
            state: formik.values.state,
            postalCode: formik.values.postalCode,
          },
          bestWayToReach: formik.values.bestWayToReach,
        };
        const { data, error } = await CureValueService(
          requestCallback,
          payload
        );

        if (!error) {
          setIsRequestMade(true);
          setTimeout(() => {
            if (onClose) {
              onClose();
            }
          }, 10000);
        }
      }
    },
  });
  useEffect(() => {
    if (currentUser.currentUser) {
      console.log(currentUser.currentUser);
      //formik.submitForm();
      formik.setValues({
        ...formik.values,
        treatmentName: treatmentId ? treatmentId : "",
        patientEmail: currentUser.currentUser.email,
        patientfName: currentUser.currentUser.name
          ? currentUser.currentUser.name.split(" ")[0]
          : "",
      });
    } else {
      window.close();
    }
  }, [currentUser]);

  return (
    // <div className="">
    //   <div className="relative">
    //     <div className="pt-[100px] realtive z-20  ">
    //       <div className="flex flex-col  justify-center px-[24px] py-[50px] max-w-[100%] lg:max-w-[60%] mx-auto shadow-lg rounded-lg border mb-[50px]">
    //         <h2 className="text-h2font lg:text-[39px] text-lightblack font-bold pb-[30px] text-center">
    //           Arrange a call with us
    //         </h2>
    //         {!isRequestMade && (
    //           <form
    //             className="form w-full"
    //             onSubmit={formik.handleSubmit}
    //             noValidate
    //           >
    //             {formik.status ? (
    //               <div className="mb-lg-15 alert alert-danger">
    //                 <div className="alert-text font-weight-bold">
    //                   {formik.status}
    //                 </div>
    //               </div>
    //             ) : (
    //               <div></div>
    //             )}
    //             <div className="flex flex-row gap-[10px] mb-[10px]">
    //               <div className="flex flex-col w-1/2">
    //                 <label className="text-pfont text-lightblack font-semibold">
    //                   First name <span className="text-[#E02B1D]">*</span>
    //                 </label>
    //                 <input
    //                   placeholder="First name"
    //                   {...formik.getFieldProps("patientfName")}
    //                   className={clsx(
    //                     "form-control bg-transparent border border-[#abbed1] py-[4px] px-[12px] rounded-[5px]",
    //                     {
    //                       "is-invalid":
    //                         formik.touched.patientfName &&
    //                         formik.errors.patientfName,
    //                     },
    //                     {
    //                       "is-valid":
    //                         formik.touched.patientfName &&
    //                         !formik.errors.patientfName,
    //                     }
    //                   )}
    //                 />
    //                 {formik.touched.patientfName &&
    //                   formik.errors.patientfName && (
    //                     <div className="fv-plugins-message-container">
    //                       <div className="fv-help-block">
    //                         <span
    //                           role="alert"
    //                           className="text-[11px] text-[#E02B1D] font-medium mb-0"
    //                         >
    //                           {formik.errors.patientfName}
    //                         </span>
    //                       </div>
    //                     </div>
    //                   )}
    //               </div>
    //               <div className="flex flex-col w-1/2">
    //                 {" "}
    //                 <label className="text-pfont text-lightblack font-semibold">
    //                   Last name
    //                 </label>
    //                 <input
    //                   placeholder="Last name"
    //                   {...formik.getFieldProps("patientlName")}
    //                   className={clsx(
    //                     "form-control bg-transparent border border-[#abbed1] py-[4px] px-[12px] rounded-[5px]",
    //                     {
    //                       "is-invalid":
    //                         formik.touched.patientlName &&
    //                         formik.errors.patientlName,
    //                     },
    //                     {
    //                       "is-valid":
    //                         formik.touched.patientlName &&
    //                         !formik.errors.patientlName,
    //                     }
    //                   )}
    //                 />
    //                 {formik.touched.patientlName &&
    //                   formik.errors.patientlName && (
    //                     <div className="fv-plugins-message-container">
    //                       <div className="fv-help-block">
    //                         <span
    //                           role="alert"
    //                           className="text-[11px] text-[#E02B1D] font-medium mb-0"
    //                         >
    //                           {formik.errors.patientlName}
    //                         </span>
    //                       </div>
    //                     </div>
    //                   )}
    //               </div>
    //             </div>
    //             <div className="flex flex-row gap-[10px] mb-[10px]">
    //               <div className="flex flex-col w-1/2">
    //                 <label className="text-pfont text-lightblack font-semibold">
    //                   Phone number<span className="text-[#E02B1D]">*</span>
    //                 </label>
    //                 <input
    //                   placeholder="Phone number"
    //                   {...formik.getFieldProps("patientPhno")}
    //                   className={clsx(
    //                     "form-control bg-transparent border border-[#abbed1] py-[4px] px-[12px] rounded-[5px]",
    //                     {
    //                       "is-invalid":
    //                         formik.touched.patientPhno &&
    //                         formik.errors.patientPhno,
    //                     },
    //                     {
    //                       "is-valid":
    //                         formik.touched.patientPhno &&
    //                         !formik.errors.patientPhno,
    //                     }
    //                   )}
    //                 />
    //                 {formik.touched.patientPhno &&
    //                   formik.errors.patientPhno && (
    //                     <div className="fv-plugins-message-container">
    //                       <div className="fv-help-block">
    //                         <span
    //                           role="alert"
    //                           className="text-[11px] text-[#E02B1D] font-medium mb-0"
    //                         >
    //                           {formik.errors.patientPhno}
    //                         </span>
    //                       </div>
    //                     </div>
    //                   )}
    //               </div>
    //               <div className="flex flex-col w-1/2">
    //                 <label className="text-pfont text-lightblack font-semibold">
    //                   Email<span className="text-[#E02B1D]">*</span>
    //                 </label>
    //                 <input
    //                   placeholder="Email"
    //                   {...formik.getFieldProps("patientEmail")}
    //                   className={clsx(
    //                     "form-control bg-transparent border border-[#abbed1] py-[4px] px-[12px] rounded-[5px]",
    //                     {
    //                       "is-invalid":
    //                         formik.touched.patientEmail &&
    //                         formik.errors.patientEmail,
    //                     },
    //                     {
    //                       "is-valid":
    //                         formik.touched.patientEmail &&
    //                         !formik.errors.patientEmail,
    //                     }
    //                   )}
    //                 />
    //                 {formik.touched.patientEmail &&
    //                   formik.errors.patientEmail && (
    //                     <div className="fv-plugins-message-container">
    //                       <div className="fv-help-block">
    //                         <span
    //                           role="alert"
    //                           className="text-[11px] text-[#E02B1D] font-medium mb-0"
    //                         >
    //                           {formik.errors.patientEmail}
    //                         </span>
    //                       </div>
    //                     </div>
    //                   )}
    //               </div>
    //             </div>

    //             <div className="flex flex-row gap-[10px] mb-[10px]">
    //               <div className="flex flex-col w-1/2">
    //                 <label className="text-pfont text-lightblack font-semibold">
    //                   Date of birth
    //                 </label>
    //                 <DatePicker
    //                   // {...formik.getFieldProps("patientDOB")}
    //                   onChange={(data) => {
    //                     if (data)
    //                       formik.setValues({
    //                         ...formik.values,
    //                         patientDOB: data,
    //                       });
    //                   }}
    //                   selected={formik.values.patientDOB}
    //                   className={clsx(
    //                     "form-control bg-transparent border border-[#abbed1] py-[4px] px-[12px] rounded-[5px] w-full",
    //                     {
    //                       "is-invalid":
    //                         formik.touched.patientDOB &&
    //                         formik.errors.patientDOB,
    //                     },
    //                     {
    //                       "is-valid":
    //                         formik.touched.patientDOB &&
    //                         !formik.errors.patientDOB,
    //                     }
    //                   )}
    //                 />
    //                 {formik.touched.patientDOB && formik.errors.patientDOB && (
    //                   <div className="fv-plugins-message-container">
    //                     <div className="fv-help-block">
    //                       <span
    //                         role="alert"
    //                         className="text-[11px] text-[#E02B1D] font-medium mb-0"
    //                       ></span>
    //                     </div>
    //                   </div>
    //                 )}
    //               </div>
    //               <div className="flex flex-col w-1/2">
    //                 <label className="text-pfont text-lightblack font-semibold">
    //                   Gender
    //                 </label>
    //                 <select
    //                   {...formik.getFieldProps("patientGender")}
    //                   className={clsx(
    //                     "form-control bg-transparent border border-[#abbed1] py-[4px] px-[12px] rounded-[5px]",
    //                     {
    //                       "is-invalid":
    //                         formik.touched.patientGender &&
    //                         formik.errors.patientGender,
    //                     },
    //                     {
    //                       "is-valid":
    //                         formik.touched.patientGender &&
    //                         !formik.errors.patientGender,
    //                     }
    //                   )}
    //                 >
    //                   <option value="Male">Male</option>
    //                   <option value="Female">Female</option>
    //                   <option value="Other">Other</option>
    //                 </select>
    //                 {formik.touched.patientGender &&
    //                   formik.errors.patientGender && (
    //                     <div className="fv-plugins-message-container">
    //                       <div className="fv-help-block">
    //                         <span
    //                           role="alert"
    //                           className="text-[11px] text-[#E02B1D] font-medium mb-0"
    //                         >
    //                           {formik.errors.patientGender}
    //                         </span>
    //                       </div>
    //                     </div>
    //                   )}
    //               </div>
    //             </div>

    //             <div className="flex flex-row gap-[10px] mb-[10px] ">
    //               <div className="flex flex-col w-1/2">
    //                 <label className="text-pfont text-lightblack font-semibold">
    //                   Treatment
    //                 </label>
    //                 <input
    //                   placeholder="Treatment"
    //                   {...formik.getFieldProps("treatmentName")}
    //                   className={clsx(
    //                     "form-control bg-transparent border border-[#abbed1] py-[4px] px-[12px] rounded-[5px]",
    //                     {
    //                       "is-invalid":
    //                         formik.touched.treatmentName &&
    //                         formik.errors.treatmentName,
    //                     },
    //                     {
    //                       "is-valid":
    //                         formik.touched.treatmentName &&
    //                         !formik.errors.treatmentName,
    //                     }
    //                   )}
    //                 />
    //                 {formik.touched.treatmentName &&
    //                   formik.errors.treatmentName && (
    //                     <div className="fv-plugins-message-container">
    //                       <div className="fv-help-block">
    //                         <span
    //                           role="alert"
    //                           className="text-[11px] text-[#E02B1D] font-medium mb-0"
    //                         >
    //                           {formik.errors.patientEmail}
    //                         </span>
    //                       </div>
    //                     </div>
    //                   )}
    //               </div>
    //             </div>
    //             <div className="flex flex-row gap-[10px] mb-[10px] ">
    //               <div className="flex flex-col w-full">
    //                 <label className="text-pfont text-lightblack font-semibold">
    //                   Describe in detail your symptoms or treatment that you are
    //                   interested in
    //                 </label>
    //                 <textarea
    //                   placeholder="Give any information that will be useful for the medical provider to understand your needs for the initial consultation."
    //                   {...formik.getFieldProps("subTreatmentName")}
    //                   className={clsx(
    //                     "form-control bg-transparent border border-[#abbed1] py-[4px] px-[12px] rounded-[5px]",
    //                     {
    //                       "is-invalid":
    //                         formik.touched.subTreatmentName &&
    //                         formik.errors.subTreatmentName,
    //                     },
    //                     {
    //                       "is-valid":
    //                         formik.touched.subTreatmentName &&
    //                         !formik.errors.subTreatmentName,
    //                     }
    //                   )}
    //                 />
    //                 {formik.touched.subTreatmentName &&
    //                   formik.errors.subTreatmentName && (
    //                     <div className="fv-plugins-message-container">
    //                       <div className="fv-help-block">
    //                         <span
    //                           role="alert"
    //                           className="text-[11px] text-[#E02B1D] font-medium mb-0"
    //                         >
    //                           {formik.errors.subTreatmentName}
    //                         </span>
    //                       </div>
    //                     </div>
    //                   )}
    //               </div>
    //             </div>

    //             <div className="flex flex-row gap-[4px] mb-[10px] items-end">
    //               <div className="flex flex-col w-1/2">
    //                 <label className="text-pfont text-lightblack font-semibold">
    //                   Need help with flight and accommodation?
    //                 </label>
    //                 <div className="flex justify-start gap-[20px]">
    //                   <label className="flex items-center gap-[5px]">
    //                     <span>Yes</span>
    //                     <input
    //                       type="radio"
    //                       {...formik.getFieldProps("shouldWeSchedule")}
    //                       value={"Yes"}
    //                       name="shouldWeSchedule"
    //                       className={clsx(
    //                         "form-control bg-transparent border border-[#abbed1] py-[4px] px-[12px] rounded-[5px]",
    //                         {
    //                           "is-invalid":
    //                             formik.touched.shouldWeSchedule &&
    //                             formik.errors.shouldWeSchedule,
    //                         },
    //                         {
    //                           "is-valid":
    //                             formik.touched.shouldWeSchedule &&
    //                             !formik.errors.shouldWeSchedule,
    //                         }
    //                       )}
    //                     />
    //                   </label>
    //                   <label className="flex items-center gap-[5px]">
    //                     <span>No</span>
    //                     <input
    //                       type="radio"
    //                       {...formik.getFieldProps("shouldWeSchedule")}
    //                       value={"No"}
    //                       name="shouldWeSchedule"
    //                       className={clsx(
    //                         "form-control bg-transparent border border-[#abbed1] py-[4px] px-[12px] rounded-[5px]",
    //                         {
    //                           "is-invalid":
    //                             formik.touched.shouldWeSchedule &&
    //                             formik.errors.shouldWeSchedule,
    //                         },
    //                         {
    //                           "is-valid":
    //                             formik.touched.shouldWeSchedule &&
    //                             !formik.errors.shouldWeSchedule,
    //                         }
    //                       )}
    //                     />
    //                   </label>
    //                 </div>

    //                 {formik.touched.shouldWeSchedule &&
    //                   formik.errors.shouldWeSchedule && (
    //                     <div className="fv-plugins-message-container">
    //                       <div className="fv-help-block">
    //                         <span
    //                           role="alert"
    //                           className="text-[11px] text-[#E02B1D] font-medium mb-0"
    //                         >
    //                           {formik.errors.shouldWeSchedule}
    //                         </span>
    //                       </div>
    //                     </div>
    //                   )}
    //               </div>
    //               <div className="flex flex-col w-1/2">
    //                 <label className="text-pfont text-lightblack font-semibold">
    //                   when would you like to travel?
    //                 </label>
    //                 <select
    //                   {...formik.getFieldProps("whenToTravel")}
    //                   className={clsx(
    //                     "form-control bg-transparent border border-[#abbed1] py-[4px] px-[12px] rounded-[5px]",
    //                     {
    //                       "is-invalid":
    //                         formik.touched.whenToTravel &&
    //                         formik.errors.whenToTravel,
    //                     },
    //                     {
    //                       "is-valid":
    //                         formik.touched.whenToTravel &&
    //                         !formik.errors.whenToTravel,
    //                     }
    //                   )}
    //                 >
    //                   <option value="Within a week">Within a week</option>
    //                   <option value="Within 3 months">Within 3 months</option>
    //                   <option value="3 months or later">
    //                     3 months or later
    //                   </option>
    //                   <option value="Not sure">Not sure</option>
    //                 </select>
    //                 {formik.touched.whenToTravel &&
    //                   formik.errors.whenToTravel && (
    //                     <div className="fv-plugins-message-container">
    //                       <div className="fv-help-block">
    //                         <span
    //                           role="alert"
    //                           className="text-[11px] text-[#E02B1D] font-medium mb-0"
    //                         >
    //                           {formik.errors.whenToTravel}
    //                         </span>
    //                       </div>
    //                     </div>
    //                   )}
    //               </div>
    //             </div>

    //             <div className="flex flex-col gap-[4px] mb-[10px]">
    //               <label className="text-pfont text-lightblack font-semibold">
    //                 What is the best way to reach you?
    //               </label>
    //               <select
    //                 {...formik.getFieldProps("bestWayToReach")}
    //                 className={clsx(
    //                   "form-control bg-transparent border border-[#abbed1] py-[4px] px-[12px] rounded-[5px]",
    //                   {
    //                     "is-invalid":
    //                       formik.touched.bestWayToReach &&
    //                       formik.errors.bestWayToReach,
    //                   },
    //                   {
    //                     "is-valid":
    //                       formik.touched.bestWayToReach &&
    //                       !formik.errors.bestWayToReach,
    //                   }
    //                 )}
    //               >
    //                 <option value="Phone">Phone</option>
    //                 <option value="Email">Email</option>
    //                 <option value="No Preference">No Preference</option>
    //               </select>
    //               {formik.touched.bestWayToReach &&
    //                 formik.errors.bestWayToReach && (
    //                   <div className="fv-plugins-message-container">
    //                     <div className="fv-help-block">
    //                       <span
    //                         role="alert"
    //                         className="text-[11px] text-[#E02B1D] font-medium mb-0"
    //                       >
    //                         {formik.errors.bestWayToReach}
    //                       </span>
    //                     </div>
    //                   </div>
    //                 )}
    //             </div>

    //             <div className="flex flex-row gap-[10px] mb-[10px] items-start">
    //               <div className="flex flex-col w-1/2">
    //                 <label className="text-pfont text-lightblack font-semibold">
    //                   Address
    //                 </label>
    //                 <textarea
    //                   placeholder="Address"
    //                   {...formik.getFieldProps("address")}
    //                   className={clsx(
    //                     "form-control bg-transparent border border-[#abbed1] py-[4px] px-[12px] rounded-[5px]",
    //                     {
    //                       "is-invalid":
    //                         formik.touched.address && formik.errors.address,
    //                     },
    //                     {
    //                       "is-valid":
    //                         formik.touched.address && !formik.errors.address,
    //                     }
    //                   )}
    //                 />
    //                 {formik.touched.address && formik.errors.address && (
    //                   <div className="fv-plugins-message-container">
    //                     <div className="fv-help-block">
    //                       <span
    //                         role="alert"
    //                         className="text-[11px] text-[#E02B1D] font-medium mb-0"
    //                       >
    //                         {formik.errors.address}
    //                       </span>
    //                     </div>
    //                   </div>
    //                 )}
    //               </div>
    //               <div className="flex flex-col w-1/2">
    //                 <label className="text-pfont text-lightblack font-semibold">
    //                   Apartment
    //                 </label>
    //                 <input
    //                   placeholder="Apartment"
    //                   {...formik.getFieldProps("apartment")}
    //                   className={clsx(
    //                     "form-control bg-transparent border border-[#abbed1] py-[4px] px-[12px] rounded-[5px]",
    //                     {
    //                       "is-invalid":
    //                         formik.touched.apartment && formik.errors.apartment,
    //                     },
    //                     {
    //                       "is-valid":
    //                         formik.touched.apartment &&
    //                         !formik.errors.apartment,
    //                     }
    //                   )}
    //                 />
    //                 {formik.touched.apartment && formik.errors.apartment && (
    //                   <div className="fv-plugins-message-container">
    //                     <div className="fv-help-block">
    //                       <span
    //                         role="alert"
    //                         className="text-[11px] text-[#E02B1D] font-medium mb-0"
    //                       >
    //                         {formik.errors.apartment}
    //                       </span>
    //                     </div>
    //                   </div>
    //                 )}
    //               </div>
    //             </div>

    //             <div className="flex flex-row gap-[10px] mb-[10px]">
    //               <div className="flex flex-col w-1/2">
    //                 <label className="text-pfont text-lightblack font-semibold">
    //                   City
    //                 </label>
    //                 <input
    //                   placeholder="City"
    //                   {...formik.getFieldProps("city")}
    //                   className={clsx(
    //                     "form-control bg-transparent border border-[#abbed1] py-[4px] px-[12px] rounded-[5px]",
    //                     {
    //                       "is-invalid":
    //                         formik.touched.city && formik.errors.city,
    //                     },
    //                     {
    //                       "is-valid":
    //                         formik.touched.city && !formik.errors.city,
    //                     }
    //                   )}
    //                 />
    //                 {formik.touched.city && formik.errors.city && (
    //                   <div className="fv-plugins-message-container">
    //                     <div className="fv-help-block">
    //                       <span
    //                         role="alert"
    //                         className="text-[11px] text-[#E02B1D] font-medium mb-0"
    //                       >
    //                         {formik.errors.city}
    //                       </span>
    //                     </div>
    //                   </div>
    //                 )}
    //               </div>
    //               <div className="flex flex-col w-1/2">
    //                 <label className="text-pfont text-lightblack font-semibold">
    //                   State
    //                 </label>
    //                 <input
    //                   placeholder="State"
    //                   {...formik.getFieldProps("state")}
    //                   className={clsx(
    //                     "form-control bg-transparent border border-[#abbed1] py-[4px] px-[12px] rounded-[5px]",
    //                     {
    //                       "is-invalid":
    //                         formik.touched.state && formik.errors.state,
    //                     },
    //                     {
    //                       "is-valid":
    //                         formik.touched.state && !formik.errors.state,
    //                     }
    //                   )}
    //                 />
    //                 {formik.touched.state && formik.errors.state && (
    //                   <div className="fv-plugins-message-container">
    //                     <div className="fv-help-block">
    //                       <span
    //                         role="alert"
    //                         className="text-[11px] text-[#E02B1D] font-medium mb-0"
    //                       >
    //                         {formik.errors.state}
    //                       </span>
    //                     </div>
    //                   </div>
    //                 )}
    //               </div>
    //             </div>

    //             <div className="flex flex-col gap-[4px] mb-[10px]"></div>

    //             <div className="flex flex-col gap-[4px] mb-[10px]">
    //               <div className="flex flex-col w-1/2">
    //                 <label className="text-pfont text-lightblack font-semibold">
    //                   Postal Code
    //                 </label>
    //                 <input
    //                   placeholder="Postal code"
    //                   {...formik.getFieldProps("postalCode")}
    //                   className={clsx(
    //                     "form-control bg-transparent border border-[#abbed1] py-[4px] px-[12px] rounded-[5px]",
    //                     {
    //                       "is-invalid":
    //                         formik.touched.postalCode &&
    //                         formik.errors.postalCode,
    //                     },
    //                     {
    //                       "is-valid":
    //                         formik.touched.postalCode &&
    //                         !formik.errors.postalCode,
    //                     }
    //                   )}
    //                 />
    //                 {formik.touched.postalCode && formik.errors.postalCode && (
    //                   <div className="fv-plugins-message-container">
    //                     <div className="fv-help-block">
    //                       <span
    //                         role="alert"
    //                         className="text-[11px] text-[#E02B1D] font-medium mb-0"
    //                       >
    //                         {formik.errors.state}
    //                       </span>
    //                     </div>
    //                   </div>
    //                 )}
    //               </div>
    //             </div>
    //             <div>
    //               Your privacy is important to us. By submitting this form, you
    //               consent to CureValue collecting and using your information to
    //               contact you regarding your inquiry and to provide updates on
    //               our services. We will not share your personal information with
    //               third parties without your consent. For more details, please
    //               read our{" "}
    //               <a
    //                 className="text-pc underline"
    //                 href="https://curevalue.org/common/privacy.php"
    //                 target="_blank"
    //               >
    //                 Privacy Policy
    //               </a>
    //               .
    //             </div>

    //             <div className="d-grid mt-[25px]">
    //               <button
    //                 type="submit"
    //                 id="kt_sign_in_submit"
    //                 className="bg-pc border border-pc text-white rounded-full w-[100%] text-pfont font-medium py-[8px] cursor-pointer group hover:bg-white disabled:cursor-not-allowed"
    //                 disabled={formik.isSubmitting}
    //               >
    //                 {!loading && (
    //                   <span className="indicator-label group-hover:text-pc">
    //                     Submit
    //                   </span>
    //                 )}
    //                 {loading && (
    //                   <span
    //                     className="indicator-progress"
    //                     style={{ display: "block" }}
    //                   >
    //                     Please wait...
    //                     <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
    //                   </span>
    //                 )}
    //               </button>
    //             </div>
    //           </form>
    //         )}
    //         {isRequestMade && (
    //           <>
    //             <div className="flex items-center justify-center text-center">
    //               Thank you for requesting a call back. Our team will get in
    //               touch with you within 48 hours.
    //             </div>
    //             <div></div>
    //           </>
    //         )}
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div className="lg:p-10 ">
      <div className="realtive z-20  ">
        <h2 className="text-[20px] lg:text-[39px] text-lightblack font-bold pb-[30px] text-center">
          Contact us to customize your treatment plan
        </h2>
        {!isRequestMade && (
          <>
            <div className="flex flex-row gap-[10px] mb-[10px]">
              <div className="flex flex-col w-full">
                <label className="lg:text-pfont text-[18px] text-lightblack font-semibold align-middle flex items-center gap-2 flex-col lg:flex-row">
                  Selected Facility <span className="hidden lg:block">:</span>{" "}
                  <span className="text-h3font block">
                    {facility.facility_name} {facility.city_name},{" "}
                    {facility.country_name}
                  </span>
                </label>
              </div>
            </div>
            <div className="flex flex-col w-full">
              <label className="text-pfont text-lightblack font-semibold align-middle flex items-center gap-2 pb-2">
                <span className="text-[#E02B1D]">*</span> Provide the details of
                the person receiving service
              </label>
            </div>
          </>
        )}
        {!isRequestMade && (
          <form className="w-full" onSubmit={formik.handleSubmit} noValidate>
            {formik.status ? (
              <div className="mb-lg-15 alert alert-danger">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            ) : (
              <div></div>
            )}
            <div className="flex flex-row gap-[10px] mb-[10px] flex-wrap lg:flex-nowrap">
              <div className="flex flex-col lg:w-1/2 w-full">
                <label className="text-pfont text-lightblack font-semibold">
                  First name <span className="text-[#E02B1D]">*</span>
                </label>
                <input
                  placeholder="First name"
                  {...formik.getFieldProps("patientfName")}
                  className={clsx(
                    "form-control bg-transparent border border-[#abbed1] py-[4px] px-[12px] rounded-[5px]",
                    {
                      "is-invalid":
                        formik.touched.patientfName &&
                        formik.errors.patientfName,
                    },
                    {
                      "is-valid":
                        formik.touched.patientfName &&
                        !formik.errors.patientfName,
                    }
                  )}
                />
                {formik.touched.patientfName && formik.errors.patientfName && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span
                        role="alert"
                        className="text-[11px] text-[#E02B1D] font-medium mb-0"
                      >
                        {formik.errors.patientfName}
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div className="flex flex-col lg:w-1/2 w-full">
                {" "}
                <label className="text-pfont text-lightblack font-semibold">
                  Last name
                </label>
                <input
                  placeholder="Last name"
                  {...formik.getFieldProps("patientlName")}
                  className={clsx(
                    "form-control bg-transparent border border-[#abbed1] py-[4px] px-[12px] rounded-[5px]",
                    {
                      "is-invalid":
                        formik.touched.patientlName &&
                        formik.errors.patientlName,
                    },
                    {
                      "is-valid":
                        formik.touched.patientlName &&
                        !formik.errors.patientlName,
                    }
                  )}
                />
                {formik.touched.patientlName && formik.errors.patientlName && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span
                        role="alert"
                        className="text-[11px] text-[#E02B1D] font-medium mb-0"
                      >
                        {formik.errors.patientlName}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-row gap-[10px] mb-[10px] flex-wrap lg:flex-nowrap">
              <div className="flex flex-col lg:w-1/2 w-full">
                <label className="text-pfont text-lightblack font-semibold">
                  Phone number<span className="text-[#E02B1D]">*</span>
                </label>
                <input
                  placeholder="Phone number"
                  {...formik.getFieldProps("patientPhno")}
                  className={clsx(
                    "form-control bg-transparent border border-[#abbed1] py-[4px] px-[12px] rounded-[5px]",
                    {
                      "is-invalid":
                        formik.touched.patientPhno && formik.errors.patientPhno,
                    },
                    {
                      "is-valid":
                        formik.touched.patientPhno &&
                        !formik.errors.patientPhno,
                    }
                  )}
                />
                {formik.touched.patientPhno && formik.errors.patientPhno && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span
                        role="alert"
                        className="text-[11px] text-[#E02B1D] font-medium mb-0"
                      >
                        {formik.errors.patientPhno}
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div className="flex flex-col lg:w-1/2 w-full">
                <label className="text-pfont text-lightblack font-semibold">
                  Email<span className="text-[#E02B1D]">*</span>
                </label>
                <input
                  placeholder="Email"
                  {...formik.getFieldProps("patientEmail")}
                  className={clsx(
                    "form-control bg-transparent border border-[#abbed1] py-[4px] px-[12px] rounded-[5px]",
                    {
                      "is-invalid":
                        formik.touched.patientEmail &&
                        formik.errors.patientEmail,
                    },
                    {
                      "is-valid":
                        formik.touched.patientEmail &&
                        !formik.errors.patientEmail,
                    }
                  )}
                />
                {formik.touched.patientEmail && formik.errors.patientEmail && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span
                        role="alert"
                        className="text-[11px] text-[#E02B1D] font-medium mb-0"
                      >
                        {formik.errors.patientEmail}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="flex flex-row gap-[10px] mb-[10px] flex-wrap lg:flex-nowrap">
              <div className="flex flex-col lg:w-1/2 w-full">
                <label className="text-pfont text-lightblack font-semibold">
                  Age
                </label>
                <input
                  {...formik.getFieldProps("patientDOB")}
                  type="number"
                  min="0"
                  // onChange={(data) => {
                  //   if (data)
                  //     formik.setValues({
                  //       ...formik.values,
                  //       patientDOB: data,
                  //     });
                  // }}
                  //selected={formik.values.patientDOB}
                  className={clsx(
                    "form-control bg-transparent border border-[#abbed1] py-[4px] px-[12px] rounded-[5px] w-full",
                    {
                      "is-invalid":
                        formik.touched.patientDOB && formik.errors.patientDOB,
                    },
                    {
                      "is-valid":
                        formik.touched.patientDOB && !formik.errors.patientDOB,
                    }
                  )}
                />
                {formik.touched.patientDOB && formik.errors.patientDOB && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span
                        role="alert"
                        className="text-[11px] text-[#E02B1D] font-medium mb-0"
                      ></span>
                    </div>
                  </div>
                )}
              </div>
              <div className="flex flex-col lg:w-1/2 w-full">
                <label className="text-pfont text-lightblack font-semibold">
                  Gender
                </label>
                <select
                  {...formik.getFieldProps("patientGender")}
                  className={clsx(
                    "form-control bg-transparent border border-[#abbed1] py-[4px] px-[12px] rounded-[5px]",
                    {
                      "is-invalid":
                        formik.touched.patientGender &&
                        formik.errors.patientGender,
                    },
                    {
                      "is-valid":
                        formik.touched.patientGender &&
                        !formik.errors.patientGender,
                    }
                  )}
                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
                {formik.touched.patientGender &&
                  formik.errors.patientGender && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span
                          role="alert"
                          className="text-[11px] text-[#E02B1D] font-medium mb-0"
                        >
                          {formik.errors.patientGender}
                        </span>
                      </div>
                    </div>
                  )}
              </div>
            </div>

            <div className="flex flex-row gap-[10px] mb-[10px] flex-wrap lg:flex-nowrap">
              <div className="flex flex-col lg:w-1/2 w-full">
                <label className="text-pfont text-lightblack font-semibold">
                  Treatment
                </label>
                <input
                  placeholder="Treatment"
                  {...formik.getFieldProps("treatmentName")}
                  className={clsx(
                    "form-control bg-transparent border border-[#abbed1] py-[4px] px-[12px] rounded-[5px]",
                    {
                      "is-invalid":
                        formik.touched.treatmentName &&
                        formik.errors.treatmentName,
                    },
                    {
                      "is-valid":
                        formik.touched.treatmentName &&
                        !formik.errors.treatmentName,
                    }
                  )}
                />
                {formik.touched.treatmentName &&
                  formik.errors.treatmentName && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span
                          role="alert"
                          className="text-[11px] text-[#E02B1D] font-medium mb-0"
                        >
                          {formik.errors.patientEmail}
                        </span>
                      </div>
                    </div>
                  )}
              </div>
            </div>
            <div className="flex flex-row gap-[10px] mb-[10px] flex-wrap lg:flex-nowrap">
              <div className="flex flex-col w-full">
                <label className="text-pfont text-lightblack font-semibold">
                  Describe in detail your symptoms or treatment that you are
                  interested in
                </label>
                <textarea
                  placeholder="Give any information that will be useful for the medical provider to understand your needs for the initial consultation."
                  {...formik.getFieldProps("subTreatmentName")}
                  className={clsx(
                    "form-control bg-transparent border border-[#abbed1] py-[4px] px-[12px] rounded-[5px]",
                    {
                      "is-invalid":
                        formik.touched.subTreatmentName &&
                        formik.errors.subTreatmentName,
                    },
                    {
                      "is-valid":
                        formik.touched.subTreatmentName &&
                        !formik.errors.subTreatmentName,
                    }
                  )}
                />
                {formik.touched.subTreatmentName &&
                  formik.errors.subTreatmentName && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span
                          role="alert"
                          className="text-[11px] text-[#E02B1D] font-medium mb-0"
                        >
                          {formik.errors.subTreatmentName}
                        </span>
                      </div>
                    </div>
                  )}
              </div>
            </div>

            <div className="flex flex-row gap-[4px] mb-[10px] items-end flex-wrap lg:flex-nowrap">
              <div className="flex flex-col lg:w-1/2 w-full">
                <label className="text-pfont text-lightblack font-semibold">
                  Need help with flight and accommodation?
                </label>
                <div className="flex justify-start gap-[20px]">
                  <label className="flex items-center gap-[5px]">
                    <span>Yes</span>
                    <input
                      type="radio"
                      {...formik.getFieldProps("shouldWeSchedule")}
                      value={"Yes"}
                      name="shouldWeSchedule"
                      className={clsx(
                        "form-control bg-transparent border border-[#abbed1] py-[4px] px-[12px] rounded-[5px]",
                        {
                          "is-invalid":
                            formik.touched.shouldWeSchedule &&
                            formik.errors.shouldWeSchedule,
                        },
                        {
                          "is-valid":
                            formik.touched.shouldWeSchedule &&
                            !formik.errors.shouldWeSchedule,
                        }
                      )}
                    />
                  </label>
                  <label className="flex items-center gap-[5px]">
                    <span>No</span>
                    <input
                      type="radio"
                      {...formik.getFieldProps("shouldWeSchedule")}
                      value={"No"}
                      name="shouldWeSchedule"
                      className={clsx(
                        "form-control bg-transparent border border-[#abbed1] py-[4px] px-[12px] rounded-[5px]",
                        {
                          "is-invalid":
                            formik.touched.shouldWeSchedule &&
                            formik.errors.shouldWeSchedule,
                        },
                        {
                          "is-valid":
                            formik.touched.shouldWeSchedule &&
                            !formik.errors.shouldWeSchedule,
                        }
                      )}
                    />
                  </label>
                </div>

                {formik.touched.shouldWeSchedule &&
                  formik.errors.shouldWeSchedule && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span
                          role="alert"
                          className="text-[11px] text-[#E02B1D] font-medium mb-0"
                        >
                          {formik.errors.shouldWeSchedule}
                        </span>
                      </div>
                    </div>
                  )}
              </div>
              <div className="flex flex-col lg:w-1/2 w-full">
                <label className="text-pfont text-lightblack font-semibold">
                  When would you like to travel?
                </label>
                <select
                  {...formik.getFieldProps("whenToTravel")}
                  className={clsx(
                    "form-control bg-transparent border border-[#abbed1] py-[4px] px-[12px] rounded-[5px]",
                    {
                      "is-invalid":
                        formik.touched.whenToTravel &&
                        formik.errors.whenToTravel,
                    },
                    {
                      "is-valid":
                        formik.touched.whenToTravel &&
                        !formik.errors.whenToTravel,
                    }
                  )}
                >
                  <option value="Within a week">Within a week</option>
                  <option value="Within 3 months">Within 3 months</option>
                  <option value="3 months or later">3 months or later</option>
                  <option value="Not sure">Not sure</option>
                </select>
                {formik.touched.whenToTravel && formik.errors.whenToTravel && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span
                        role="alert"
                        className="text-[11px] text-[#E02B1D] font-medium mb-0"
                      >
                        {formik.errors.whenToTravel}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="flex flex-col gap-[4px] mb-[10px] flex-wrap lg:flex-nowrap">
              <label className="text-pfont text-lightblack font-semibold">
                What is the best way to reach you?
              </label>
              <select
                {...formik.getFieldProps("bestWayToReach")}
                className={clsx(
                  "form-control bg-transparent border border-[#abbed1] py-[4px] px-[12px] rounded-[5px]",
                  {
                    "is-invalid":
                      formik.touched.bestWayToReach &&
                      formik.errors.bestWayToReach,
                  },
                  {
                    "is-valid":
                      formik.touched.bestWayToReach &&
                      !formik.errors.bestWayToReach,
                  }
                )}
              >
                <option value="Phone">Phone</option>
                <option value="Email">Email</option>
                <option value="No Preference">No Preference</option>
              </select>
              {formik.touched.bestWayToReach &&
                formik.errors.bestWayToReach && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span
                        role="alert"
                        className="text-[11px] text-[#E02B1D] font-medium mb-0"
                      >
                        {formik.errors.bestWayToReach}
                      </span>
                    </div>
                  </div>
                )}
            </div>

            <div className="flex flex-row gap-[10px] mb-[10px] items-start flex-wrap lg:flex-nowrap">
              <div className="flex flex-col lg:w-1/2 w-full">
                <label className="text-pfont text-lightblack font-semibold">
                  Address
                </label>
                <textarea
                  placeholder="Address"
                  {...formik.getFieldProps("address")}
                  className={clsx(
                    "form-control bg-transparent border border-[#abbed1] py-[4px] px-[12px] rounded-[5px]",
                    {
                      "is-invalid":
                        formik.touched.address && formik.errors.address,
                    },
                    {
                      "is-valid":
                        formik.touched.address && !formik.errors.address,
                    }
                  )}
                />
                {formik.touched.address && formik.errors.address && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span
                        role="alert"
                        className="text-[11px] text-[#E02B1D] font-medium mb-0"
                      >
                        {formik.errors.address}
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div className="flex flex-col lg:w-1/2 w-full">
                <label className="text-pfont text-lightblack font-semibold">
                  Apartment
                </label>
                <input
                  placeholder="Apartment"
                  {...formik.getFieldProps("apartment")}
                  className={clsx(
                    "form-control bg-transparent border border-[#abbed1] py-[4px] px-[12px] rounded-[5px]",
                    {
                      "is-invalid":
                        formik.touched.apartment && formik.errors.apartment,
                    },
                    {
                      "is-valid":
                        formik.touched.apartment && !formik.errors.apartment,
                    }
                  )}
                />
                {formik.touched.apartment && formik.errors.apartment && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span
                        role="alert"
                        className="text-[11px] text-[#E02B1D] font-medium mb-0"
                      >
                        {formik.errors.apartment}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="flex flex-row gap-[10px] mb-[10px] flex-wrap lg:flex-nowrap">
              <div className="flex flex-col lg:w-1/2 w-full">
                <label className="text-pfont text-lightblack font-semibold">
                  City
                </label>
                <input
                  placeholder="City"
                  {...formik.getFieldProps("city")}
                  className={clsx(
                    "form-control bg-transparent border border-[#abbed1] py-[4px] px-[12px] rounded-[5px]",
                    {
                      "is-invalid": formik.touched.city && formik.errors.city,
                    },
                    {
                      "is-valid": formik.touched.city && !formik.errors.city,
                    }
                  )}
                />
                {formik.touched.city && formik.errors.city && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span
                        role="alert"
                        className="text-[11px] text-[#E02B1D] font-medium mb-0"
                      >
                        {formik.errors.city}
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div className="flex flex-col lg:w-1/2 w-full">
                <label className="text-pfont text-lightblack font-semibold">
                  State
                </label>
                <input
                  placeholder="State"
                  {...formik.getFieldProps("state")}
                  className={clsx(
                    "form-control bg-transparent border border-[#abbed1] py-[4px] px-[12px] rounded-[5px]",
                    {
                      "is-invalid": formik.touched.state && formik.errors.state,
                    },
                    {
                      "is-valid": formik.touched.state && !formik.errors.state,
                    }
                  )}
                />
                {formik.touched.state && formik.errors.state && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span
                        role="alert"
                        className="text-[11px] text-[#E02B1D] font-medium mb-0"
                      >
                        {formik.errors.state}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="flex flex-col gap-[4px] mb-[10px]"></div>

            <div className="flex flex-col gap-[4px] mb-[10px] flex-wrap lg:flex-nowrap">
              <div className="flex flex-col lg:w-1/2 w-full">
                <label className="text-pfont text-lightblack font-semibold">
                  Postal Code
                </label>
                <input
                  placeholder="Postal code"
                  {...formik.getFieldProps("postalCode")}
                  className={clsx(
                    "form-control bg-transparent border border-[#abbed1] py-[4px] px-[12px] rounded-[5px]",
                    {
                      "is-invalid":
                        formik.touched.postalCode && formik.errors.postalCode,
                    },
                    {
                      "is-valid":
                        formik.touched.postalCode && !formik.errors.postalCode,
                    }
                  )}
                />
                {formik.touched.postalCode && formik.errors.postalCode && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span
                        role="alert"
                        className="text-[11px] text-[#E02B1D] font-medium mb-0"
                      >
                        {formik.errors.state}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div>
              Your privacy is important to us. By submitting this form, you
              consent to CureValue collecting and using your information to
              contact you regarding your inquiry and to provide updates on our
              services. We will not share your personal information with third
              parties without your consent. For more details, please read our{" "}
              <a
                className="text-pc underline"
                href="https://curevalue.org/common/privacy.php"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              .
            </div>

            <div className="d-grid mt-[25px]">
              <button
                type="submit"
                id="kt_sign_in_submit"
                className="bg-pc border border-pc text-white rounded-full w-[100%] text-pfont font-medium py-[8px] cursor-pointer group hover:bg-white disabled:cursor-not-allowed"
                disabled={formik.isSubmitting}
              >
                {!loading && (
                  <span className="indicator-label group-hover:text-pc">
                    Submit
                  </span>
                )}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        )}
        {isRequestMade && (
          <>
            <div className="flex items-center justify-center text-center">
              Thank you for requesting a call back. Our team will get in touch
              with you within 2 days.
            </div>
            <div></div>
          </>
        )}
      </div>
    </div>
  );
};
export default AppointmentSchedule;
