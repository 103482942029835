import { Facility } from "../../modules/facility";
import React from 'react'
import { useNavigate } from 'react-router-dom';
import defaultFacilityImage from "../../../assets/images/cv-hospital-illustration.jpg"
import styles from './CompareBar.module.css'

interface CompareBarProps {
  selectedFacilities: Facility[];
  clearFacilities: () => void;
  setSelectedFacilities: React.Dispatch<React.SetStateAction<Facility[]>>;
  uncheckFacility: (facilityId: string) => void;
}

const FACILITY_IMAGE_CDN = "https://dat1iz0i6xycu.cloudfront.net/facility_images/";

const CompareBar = ({ selectedFacilities, clearFacilities, setSelectedFacilities,uncheckFacility }: CompareBarProps) => {

  const navigate = useNavigate();

  if (selectedFacilities.length === 0) return null;

  const removeFacility = (facility: Facility) => {
  setSelectedFacilities((prevFacilities) => {
    const updatedFacilities = prevFacilities.filter(item => item._id !== facility._id);
    localStorage.setItem("comparedFacilities", JSON.stringify(updatedFacilities));
    return updatedFacilities;
  });
  uncheckFacility(facility._id);
  };

  const handleCompare = () => {
  const facilityNames = selectedFacilities
    .filter(facility => 
      facility.facility_name && 
      facility.city_name && 
      facility.country_name // Ensure all required properties exist
    )
    .map(facility => 
      `${facility.facility_name.toLowerCase().replace(/\s+/g, '-')}-city-${facility.city_name.toLowerCase().replace(/\s+/g, '-')}-country-${facility.country_name.toLowerCase().replace(/\s+/g, '-')}`
    );

  if (facilityNames.length >= 2 && facilityNames.length <= 4) {
    // Construct the URL with the desired format
    navigate(`/compare/${facilityNames.join("-vs-")}`);
  }
};



  return (
    <div className={`fixed bottom-2 left-1/2 transform -translate-x-1/2 max-w-2xl bg-white p-2 lg:p-3 shadow-lg flex flex-wrap items-center justify-between border border-gray-200 rounded-lg z-50 w-[40rem] ${styles.compareBar}`}>
      <div className={`flex flex-wrap gap-2 items-center ${styles.facilitiesContainer}`}>
        {selectedFacilities.map(facility => (
          <div key={facility._id} className={`relative w-16 h-16 lg:w-20 lg:h-15 ${styles.facilties}`}>
            <img
              src={`${FACILITY_IMAGE_CDN + facility._id}/${facility.image_urls[0]}`} 
              alt={facility.facility_name}
              className={`w-full h-full object-cover rounded-lg ${styles.facilityImage}`}
              onClick={(e) => e.stopPropagation()}
              onError={(e) => {
                e.currentTarget.src = defaultFacilityImage;
              }}
            />
            <button
              className={`absolute top-0 right-0 bg-black text-white rounded-full w-4 h-4 lg:w-5 lg:h-5 flex items-center justify-center z-10 ${styles.removeFacility}`}
              onClick={(e) => {
                e.stopPropagation()
                removeFacility(facility);
              }}
            >
              ×
            </button>
          </div>
        ))}
      </div>

      <div className="flex gap-2 md:mr-4 lg:mt-0">
        <button type="button" className={`text-gray-600 font-medium text-xs lg:text-sm ${styles.clearButton}`} onClick={(e) => {
            e.stopPropagation();
            clearFacilities()
        }}>
          × Clear all
        </button>
        <button type="button" className={`bg-pc border border-pc text-white rounded-full font-anchor font-medium py-[7px] px-[27px] hover:bg-white hover:text-pc ${styles.compareButton}`} onClick={handleCompare} disabled={selectedFacilities.length < 2 || selectedFacilities.length > 4}>
        Compare
        </button>
      </div>
    </div>
  );
};

export default CompareBar;
