import { ChangeEvent, MouseEvent } from "react";
import { IoClose } from "react-icons/io5";
import cardimg from "../../../assets/images/destination-img1.png";
import { City } from "../../modules/city";
import { CITY_IMAGE_CDN } from "../shared/urlConstants";

interface CityCompareCardProps {
  city: City;
  onCloseClicked?: (e: MouseEvent) => void;
  onCompareChanged?: (e: ChangeEvent<HTMLInputElement>) => void;
  isComparePage: boolean;
  isCompareChecked?: boolean;
  isRemoveFavoriteEnabled: boolean;
  compareLimitReached?: boolean;
  onClick?: (e: MouseEvent<HTMLDivElement>, entity: City) => void;
}

const CityCompareCard = ({
  city,
  onCloseClicked,
  onCompareChanged,
  isComparePage,
  isCompareChecked,
  isRemoveFavoriteEnabled,
  compareLimitReached,
  onClick,
}: CityCompareCardProps) => {
  return (
    <>
      <div
        onClick={(e) => {
          if (onClick) {
            onClick(e, city);
          }
        }}
        className={`cursor-pointer  border-[3px] border-borderclr hover:border-hoverclr rounded-[30px]  px-[20px] pb-[25px] bg-white pt-[130px]  w-[390px] relative shrink-0 ${
          isComparePage === false ? "min-h-[450px]" : "min-h-[400px]"
        }`}
      >
        <img
          src={
            city?.image_name ? `${CITY_IMAGE_CDN + city.image_name}` : cardimg
          }
          //src={cardimg}
          alt={city.city_name.toLocaleLowerCase()}
          className="w-full lg:max-w-[340px]  m-auto absolute left-0 right-0 top-[-50px] h-[165px] rounded-[25px] object-cover"
        />
        {isRemoveFavoriteEnabled && (
          <div
            className="absolute top-[-40px] right-[40px] text-[20px] p-[8px] bg-[#d9e0d9bd] text-hoverclr rounded-full cursor-pointer"
            onClick={(e: MouseEvent) => {
              if (onCloseClicked) {
                onCloseClicked(e);
              }
            }}
          >
            <IoClose />
            {/* <-raIoMdHeart /> */}
          </div>
        )}
        <p className="flex items-center m-auto gap-[5px] text-h6font lg:text-pfont rounded-full bg-[#1A38601A] px-[10px] py-[5px] lg:px-[10px] lg:py-[8px] font-semibold justify-center w-fit">
          CureValue score :<span className="pr-2">{city?.city_rating}</span>
        </p>
        <div className="flex items-center justify-between lg:pt-[13px] 2xl:pt-[31px] pb-[8px] mt-[10px] lg:mt-[0]">
          <div className="flex items-center gap-[13px]">
            {/* <p className="bg-pc text-white text-[15px] lg:text-h4font px-[15px] py-[5px] rounded-full w-fit font-semibold">
              $450
            </p> */}
            <div className="flex items-center gap-[13px]">
              <p className="bg-[green] border-hoverclr border-1 border-solid text-white text-[15px] lg:text-h4font px-[15px] py-[5px] rounded-[50%]  w-[60px] h-[60px] font-semibold flex justify-center items-center">
                {Math.round(100 - parseFloat(city.affordability_index))}%
              </p>
              <div>
                <h2 className="text-sc text-[22px] font-bold ">
                  Savings
                </h2>
                <p className="text-greyclr text-[4px] lg:text-smalltext ">
                  {" "}
                  on average compared to the U.S.
                </p>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-[13px]">
            {/* <IoMdInformationCircleOutline className="cursor-pointer text-[20px] text-iconclr2 lg:text-h5font" /> */}
            <img
              src={`${
                process.env.PUBLIC_URL
              }/media/flags/Property 1=${city.country_name.toLocaleLowerCase()}.svg`}
              className="w-[100%] max-w-[60px]"
              alt="Property"
            />
          </div>
        </div>
        <h2 className="text-sc text-h3font/[28px] lg:text-h5font/[38px] font-semibold pb-[10px]  ">
          {city.city_name}
          <span className="text-h4font lg:text-[25px]/[30px] block">
            {city.country_name}
          </span>
        </h2>
        {!isComparePage && (
          <div className="absolute bottom-[20px] left-[20px] right-[20px]">
            <div className="flex items-center">
              <input
                type="checkbox"
                className="mr-2"
                onChange={onCompareChanged}
                checked={isCompareChecked}
                disabled={!isCompareChecked && compareLimitReached}
              />
              Add to compare
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CityCompareCard;
