import { FaArrowRightLong } from "react-icons/fa6";
import { getURLFromAssetsId } from "../cms/CMSHelper";
import contentIdMap from "../../../assets/content/contentful.json";
import { useGetCMS } from "../cms/CMSContext";
import { getTreatmentFromCMSTreatment } from "../../../helpers/CVHelper";
import { useTreatment } from "../../modules/treatment";
import { useNavigate } from "react-router-dom";

const Stories = (props: any) => {
  return (
    <div className="flex justify-center items-center bg-white rounded-[30px] px-[25px] lg:px-[30px] py-[33px] stories-card h-[450px] lg:h-fit">
      <div className="flex flex-col gap-[14px] lg:gap-[37px] justify-between text-left items-start h-full">
        <div className="text-black">
          <h3 className="text-[24px] font-semibold  flex align-center justify-between">
            {props.title}
            <img
              src={
                props.entries.length != 0
                  ? getURLFromAssetsId(
                      props.entries[0]?.treatmentIconHover?.sys.id,
                      props.entries[0]
                    )
                  : `${process.env.PUBLIC_URL}/media/treatment_Icons/cardiology_hover.svg`
              }
              className="w-[50px] h-[50px] max-w-[60px]"
              alt="title"
            />
          </h3>
          {props.entries.length != 0 && (
            <h2 className="pb-[17px] text-[18px] font-medium">
              {props.entries[0].treatmentName}
            </h2>
          )}
          <p className="text-anchortext lg:text-h4font/[22px] font-medium h-max-[200px] ">
            {props.subText}
          </p>
        </div>
        <div>
          <a
            className="flex items-center gap-[5px] text-blueclr font-semibold text-smalltext lg:text-pfont cursor-pointer group hover:text-hoverclr"
            href={props.ctaLink}
          >
            {props.ctaText}
            <span>
              <FaArrowRightLong className="text-blueclr text-pfont group-hover:text-hoverclr" />{" "}
            </span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Stories;
